import React, { useState, useLayoutEffect } from "react";
import { Row, Col } from 'antd';
import "./menuBar.css";
import { Picture } from 'react-responsive-picture';
import { store } from "../redux/store";
import WidthPage from "./HookDimensions";
import "./menubarResponsive.css";
import Headroom from "react-headroom";
import $ from 'jquery';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const logoStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
};

const mStyleResponsiveLogo = {
    backgroundColor: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    flexDirection: "column",
    maxHeight: "120px",
    padding: "0px",
    borderBottom: "0.5px solid black",
    minHeight: "55px",
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
    borderBottom: "1px solid white"
};

export const MenuBar = () => {
    const [isOpen, setIsOpen] = useState(true);
    useWindowSize(); // Ensure it's called to manage window size updates

    function menuResponsive(action) {
        const wi = parseInt($("#widthPaheAll").text());
        if (wi < 900) {
            const menu = $(".menu");
            const menuIcon = $(".menuIcon");
            const closeIcon = $(".closeMenuRes");

            if (action === "open") {
                menu.addClass("showMenu");
                closeIcon.css("display", "block");
                menuIcon.css("display", "none");
                $('html, body').css({
                    overflow: 'hidden',
                    height: '100%'
                });
            } else if (action === "close") {
                menu.removeClass("showMenu");
                closeIcon.css("display", "none");
                menuIcon.css("display", "block");
                $('html, body').css({
                    overflow: '',
                    height: ''
                });
            }
        } else {
            if (action === "open") {
                if (isOpen === true) {
                    setIsOpen(false);
                    $(".menuNavBar").addClass("showMenuBar");
                    $(".iconRotate").css("transform", "rotate(180deg)");
                    $(".menuNavBar").fadeOut(100);
                } else {
                    setIsOpen(true);
                    $(".menuNavBar").removeClass("showMenuBar");
                    $(".iconRotate").css("transform", "");
                    $(".menuNavBar").fadeIn(300);
                }
            }
        }
    }

    return $(window).width() < 900 ? (
        <Headroom>
            <div className="menuBarApp">
                <nav className="Nav">
                    <WidthPage />
                    <Row>
                        <Col className="colMenuROTD" span={24} style={mStyleResponsiveLogo}>
                            <div style={logoStyle} onClick={() => menuResponsive("open")} className="containerMenuBarLogo">
                                <Picture className="img-loro-ori img-logo-ori-responsive" src={require("../resources/logoForBlackFinal.png").default} />
                                <Picture className="img-loro-ori-text img-loro-ori-text-responsive" src={require("../resources/ctvonly.png").default} />
                                <Picture className="img-arrow-responsive" src={require("../resources/icons8-down-96.png").default} />
                            </div>
                        </Col>
                    </Row>
                </nav>
            </div>
        </Headroom>
    ) : (
        <div></div>
    );
}
