import React from 'react';
import '../Grid/gridAlbum.css';
import { Observable as ObservableRxJs, Subject } from 'rxjs';
import { store } from '../redux/store';
import { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Observable } from '../../node_modules/object-observer/dist/object-observer';
import { NestedGrid } from './dataGrid';
import "./imgComponent.css";
import $ from 'jquery';
import { ModalModal2 } from '../Modal/modalNer';
import { Button } from 'antd';
import "../home/splitPage.css";
import "../RecordOfTheDay/recordOfTheDay.css";
import "../RecordOfTheDay/topPageInfo.css";
import TableNoCover from './TableNoCover';
import WidthPage from "../home/HookDimensions";
import "../RecordOfTheDay/recordOfTheDayResponsive.css";
import { Row, Col } from 'antd';
import { Picture } from 'react-responsive-picture';
import Footer from '../footer/footer';
import { firstFunctionApp } from '../App';
import { ModalAlbumJs } from '../Modal/modalAlbum';
import FormDay from '../form/formNewChangeDay';
import { useReducer } from 'react';
import HorizontallyScrollingText from './HorizontalScrollText';
import { ModalInfoSite } from '../Modal/modalInfoSite';
import EqAnim from '../animation/eqAnimation';
import Headroom from "react-headroom";
import BurgerMenuComponent from './BurgherMenu';
import Marquee from 'react-fast-marquee';
import MenuToggleButton from './MenuToggleButton';




const moment = require('moment');


const urlTestCtv = "https://testctv.herokuapp.com";
var popolateArrayChangeDay = [];
const observableChangeDay = Observable.from(popolateArrayChangeDay);

var arrayRxJsObservable = [];
var arraySubject = new Subject;

var pushToArray = function (item) {
  arrayRxJsObservable.push(item);
  arraySubject.next(item);
}


var arrayRxJsObservableGridAlbum = [];
var arraySubjectGridAlbum = new Subject;

var pushToArrayGridAlbum = function (item) {
  arrayRxJsObservableGridAlbum.push(item);
  arraySubjectGridAlbum.next(item);
}

arraySubjectGridAlbum.subscribe((item) => {
  // GridAlbum();
});

const datesModal = new Date();
const formattedDateModal = moment(datesModal).format('YYYY-MM-DD');
var localDateModal = formattedDateModal;
var newDateForModal = localDateModal;


var album = [];
var albumPresentiDirectoryCover = [];
var albumPresentiDirectoryCoverObj = [];
var albumNonPresentiDirectoryCoverObj = [];
var arrayTendina = ["sub1"];
var allAlbumFoundId = [];
var uniqueNoCoverArray = [];

//funzione che elimina i duplicati in un array, in cui data è l'array e key la chiave con la quale trovare i duplicati
function uniqueArray(data, key) {
  if (data && key) {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }
  else {
    return <div></div>
  }
}

var styleText = {
  overflow: 'hidden',
  letterSpacing: '1px',
  animation: 'typing 2.0s steps(' + 40 + ', end), blink 0.4s step-end infinite, background-fade 2.0s step-end forwards',
  whiteSpace: 'nowrap',
  fontSize: '23px',
  borderRight: '2px solid orange',
  boxSizing: 'border-box',
  wordBreak: 'break-all',
  marginTop: '10px'
}

var styleTextRes = {
  overflow: 'hidden',
  letterSpacing: '1px',
  animation: 'typing 2.0s steps(' + 32 + ', end), blink 0.4s step-end infinite, background-fade 2.0s step-end forwards',
  whiteSpace: 'nowrap',
  fontSize: '23px',
  borderRight: '2px solid orange',
  boxSizing: 'border-box',
  wordBreak: 'break-all',
}

const mStyleResponsive = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  maxHeight: "110px",
  paddingTop: "6px",
  paddingBottom: "6px",
  justifyContent: "center",
  zIndex: "50",
  borderBottom: "1.2px solid black"
};

const mStyleResponsive1 = {
  display: "flex",
  alignItems: "flexStart",
  justifyContent: "center",
  flexDirection: "column",
  maxHeight: "110px",
  paddingTop: "6px",
  paddingBottom: "6px",
  borderBottom: "1.2px solid black"
};

var observableAperturaTendinaDecadi = Observable.from(arrayTendina);

//
var counterAlbum50 = 0;
var counterAlbum80 = 0;
var counterAlbum2000 = 0;
var counterAlbum2010 = 0;
//
var counterTemp50 = 0;
var counterTemp80 = 0;
var counterTemp2000 = 0;
var counterTemp2010 = 0;
//
var indice50start = 0;
var indice80start = 0;
var indice2000start = 0;
var indice2010start = 0;
//
var elements80 = [];
var elements50 = [];
var elements2000 = [];
var elements2010 = [];
//
var arrayTemp50 = [];
var arrayTemp80 = [];
var arrayTemp2000 = [];
var arrayTemp2010 = [];

//
var fiftySixtySeventyAlbumObj = [];
var eightyNinetyAlbumObj = [];
var twothousandTwothousandTenAlbumObj = [];
var twothousandTenTodayAlbumObj = [];

//
var fiftySixtySeventyAlbumNoCover = [];
var eightyNinetyAlbumNoCover = [];
var twothousandTwothousandTenAlbumNoCover = [];
var twothousandTenTodayAlbumNoCover = [];

var arrayAlbumTotali2 = [];

var firstEsecutionChangeDay = true;
var globalCounterAlbum = 0;
var totalAlbum = [];
var fiftySixtySeventyAlbum = [];
var eightyNinetyAlbum = [];
var twothousandTwothousandTenAlbum = [];
var twothousandTenTodayAlbum = [];

var firstPopolateArrayAlbum80Check = true;
var firstPopolateArrayAlbum50Check = true;
var firstPopolateArrayAlbum2000Check = true;
var firstPopolateArrayAlbum2010Check = true;

var checkVisibleModal = false;

var firstPopolateFirst50ChangeDay = true;
var firstPopolateFirst80ChangeDay = true;
var firstPopolateFirst2000ChangeDay = true;
var firstPopolateFirst2010ChangeDay = true;

function formatStringGriglia(s) {
  var res = "";
  if (s.length > 100) {
    res = s.substring(0, 100) + "...";
  }
  else {
    res = s;
  }
  return res;
}


function filterObjArrayForId(array) {
  //filtra un array di oggetti con duplicati
  array = albumPresentiDirectoryCoverObj.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.id === value.id
    ))
  )
  return array;
}

function getAlbumPresentiCoverObj(albumPresenti, albumTotali) {
  for (var i = 0; i < albumTotali.length; i++) {
    for (var j = 0; j < albumPresenti.length; j++) {
      if (albumTotali[i].id == albumPresenti[j]) {
        albumPresentiDirectoryCoverObj.push(albumTotali[i]);
      }
    }
  }
  //filtra un array di oggetti con duplicati
  albumPresentiDirectoryCoverObj = albumPresentiDirectoryCoverObj.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.id === value.id
    ))
  )
  return albumPresentiDirectoryCoverObj;
}

function getAlbumNonPresentiCoverObj(albumNonPresenti, albumTotali) {
  for (var i = 0; i < albumTotali.length; i++) {
    for (var j = 0; j < albumNonPresenti.length; j++) {
      if (albumTotali[i].id == albumNonPresenti[j]) {
        albumNonPresentiDirectoryCoverObj.push(albumTotali[i]);
      }
    }
  }
  //filtra un array di oggetti con duplicati
  albumNonPresentiDirectoryCoverObj = albumNonPresentiDirectoryCoverObj.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.id === value.id
    ))
  )
  return albumNonPresentiDirectoryCoverObj;
}


export async function popolateDecadiArray(arrayAlbumTotali) {
  //console.log("popolateArray-----");
  //console.log("popolateArrayDecadi albumtotali", arrayAlbumTotali);
  totalAlbum = store.getState();
  if (arrayAlbumTotali.length > 0) {

    arrayAlbumTotali2 = arrayAlbumTotali.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.id === value.id
      ))
    )
  }
  //logiche di gestione no cover trovate
  if (totalAlbum.length > 0) {
    totalAlbum.forEach((element) => {
      allAlbumFoundId.push(element.id);
    });
    for (var i = 0; i < allAlbumFoundId.length; i++) {
      for (var j = 0; j < albumPresentiDirectoryCover.length; j++) {
        if (allAlbumFoundId[i] === albumPresentiDirectoryCover[j]) {
          allAlbumFoundId.splice(i, 1);
        }
      }
    }
  }
  //array con tutte gli album per i quali non sono presenti cover nel db
  uniqueNoCoverArray = [...new Set(allAlbumFoundId)];

  getAlbumPresentiCoverObj(albumPresentiDirectoryCover, arrayAlbumTotali2);
  getAlbumNonPresentiCoverObj(uniqueNoCoverArray, arrayAlbumTotali2);

  albumNonPresentiDirectoryCoverObj = albumNonPresentiDirectoryCoverObj.filter(val => !albumPresentiDirectoryCoverObj.includes(val));

  //vengono resettate tutte le variabili di popolamento griglia ogni volta che si cambia pagina e si riclicca su recordOftheDay
  counterAlbum50 = 0;
  counterAlbum80 = 0;
  counterAlbum2000 = 0;
  counterAlbum2010 = 0;
  //
  counterTemp50 = 0;
  counterTemp80 = 0;
  counterTemp2000 = 0;
  counterTemp2010 = 0;
  //
  indice50start = 0;
  indice80start = 0;
  indice2000start = 0;
  indice2010start = 0;

  elements80 = [];
  elements50 = [];
  elements2000 = [];
  elements2010 = [];
  //
  arrayTemp50 = [];
  arrayTemp80 = [];
  arrayTemp2000 = [];
  arrayTemp2010 = [];

  fiftySixtySeventyAlbum = [];
  eightyNinetyAlbum = [];
  twothousandTwothousandTenAlbum = [];
  twothousandTenTodayAlbum = [];

  fiftySixtySeventyAlbumNoCover = [];
  eightyNinetyAlbumNoCover = [];
  twothousandTwothousandTenAlbumNoCover = [];
  twothousandTenTodayAlbumNoCover = [];

  fiftySixtySeventyAlbumObj = [];
  eightyNinetyAlbumObj = [];
  twothousandTwothousandTenAlbumObj = [];
  twothousandTenTodayAlbumObj = [];

  //funzione che assegna il valore rate agli oggetti in base ai rating presenti
  function calcolaRate(array) {
    array.forEach(item => {
      let rate = 0;

      // Controllo se i valori non sono vuoti per rateyourmusic, wikidata e allmusic
      if (item.rateyourmusic !== "" && item.wikidata !== "" && item.allmusic !== "") {
        rate = 3;
      } else if ((item.rateyourmusic !== "" && item.wikidata !== "") ||
        (item.rateyourmusic !== "" && item.allmusic !== "") ||
        (item.wikidata !== "" && item.allmusic !== "")) {
        rate = 2;
      } else if (item.rateyourmusic !== "" || item.wikidata !== "" || item.allmusic !== "") {
        rate = 1;
      }
      // Aggiungo la chiave "rate" all'oggetto corrente
      item.rate = rate;
    });

    return array;
  }

  function ordinaPerRate(array) {
    // Ordina l'array in base al rate in ordine decrescente
    array.sort((a, b) => {
      // Se il rate è diverso, ordina in base al rate
      if (a.rate !== b.rate) {
        return b.rate - a.rate;
      } else {
        // Se il rate è lo stesso, ordina in base alla data in ordine crescente
        return new Date(a.Date) - new Date(b.Date);
      }
    });
    return array;
  }

  //popolo gli array decadi solo con gli album che hanno cover presente nel db
  for (var i = 0; i < albumPresentiDirectoryCoverObj.length; i++) {
    if (parseInt(albumPresentiDirectoryCoverObj[i].Date.substring(0, 4)) >= 1950 && parseInt(albumPresentiDirectoryCoverObj[i].Date.substring(0, 4)) < 1980) {
      fiftySixtySeventyAlbum.push(albumPresentiDirectoryCoverObj[i]);
    }
    if (parseInt(albumPresentiDirectoryCoverObj[i].Date.substring(0, 4)) >= 1980 && parseInt(albumPresentiDirectoryCoverObj[i].Date.substring(0, 4)) < 2000) {
      eightyNinetyAlbum.push(albumPresentiDirectoryCoverObj[i]);
    }
    if (parseInt(albumPresentiDirectoryCoverObj[i].Date.substring(0, 4)) >= 2000 && parseInt(albumPresentiDirectoryCoverObj[i].Date.substring(0, 4)) < 2010) {
      twothousandTwothousandTenAlbum.push(albumPresentiDirectoryCoverObj[i]);
    }
    if (parseInt(albumPresentiDirectoryCoverObj[i].Date.substring(0, 4)) >= 2010) {
      twothousandTenTodayAlbum.push(albumPresentiDirectoryCoverObj[i]);
    }
  }

  //popolo gli array decadi con tutti gli album, anche con quelli che non hanno una cover
  for (var i = 0; i < arrayAlbumTotali2.length; i++) {
    if (parseInt(arrayAlbumTotali2[i].Date.substring(0, 4)) >= 1950 && parseInt(arrayAlbumTotali2[i].Date.substring(0, 4)) < 1980) {
      fiftySixtySeventyAlbumObj.push(arrayAlbumTotali2[i]);
    }
    if (parseInt(arrayAlbumTotali2[i].Date.substring(0, 4)) >= 1980 && parseInt(arrayAlbumTotali2[i].Date.substring(0, 4)) < 2000) {
      eightyNinetyAlbumObj.push(arrayAlbumTotali2[i]);
    }
    if (parseInt(arrayAlbumTotali2[i].Date.substring(0, 4)) >= 2000 && parseInt(arrayAlbumTotali2[i].Date.substring(0, 4)) < 2010) {
      twothousandTwothousandTenAlbumObj.push(arrayAlbumTotali2[i]);
    }
    if (parseInt(arrayAlbumTotali2[i].Date.substring(0, 4)) >= 2010) {
      twothousandTenTodayAlbumObj.push(arrayAlbumTotali2[i]);
    }
  }


  //popolo gli array delle decadi solamente con gli album senza cover
  for (var i = 0; i < albumNonPresentiDirectoryCoverObj.length; i++) {
    if (parseInt(albumNonPresentiDirectoryCoverObj[i].Date.substring(0, 4)) >= 1950 && parseInt(albumNonPresentiDirectoryCoverObj[i].Date.substring(0, 4)) < 1980) {
      fiftySixtySeventyAlbumNoCover.push(albumNonPresentiDirectoryCoverObj[i]);
    }
    if (parseInt(albumNonPresentiDirectoryCoverObj[i].Date.substring(0, 4)) >= 1980 && parseInt(albumNonPresentiDirectoryCoverObj[i].Date.substring(0, 4)) < 2000) {
      eightyNinetyAlbumNoCover.push(albumNonPresentiDirectoryCoverObj[i]);
    }
    if (parseInt(albumNonPresentiDirectoryCoverObj[i].Date.substring(0, 4)) >= 2000 && parseInt(albumNonPresentiDirectoryCoverObj[i].Date.substring(0, 4)) < 2010) {
      twothousandTwothousandTenAlbumNoCover.push(albumNonPresentiDirectoryCoverObj[i]);
    }
    if (parseInt(albumNonPresentiDirectoryCoverObj[i].Date.substring(0, 4)) >= 2010) {
      twothousandTenTodayAlbumNoCover.push(albumNonPresentiDirectoryCoverObj[i]);
    }
  }

  //console.log("noCover--50", fiftySixtySeventyAlbumNoCover);
  //console.log("noCover--80", eightyNinetyAlbumNoCover);
  //console.log("noCover--2000", twothousandTwothousandTenAlbumNoCover);
  //console.log("noCover--2010", twothousandTenTodayAlbumNoCover);

  //console.log("all--50Obj", fiftySixtySeventyAlbumObj);
  //console.log("all--80Obj", eightyNinetyAlbumObj);
  //console.log("all--2000Obj", twothousandTwothousandTenAlbumObj);
  //console.log("all--2010Obj", twothousandTenTodayAlbumObj);

  ordinaPerRate(calcolaRate(fiftySixtySeventyAlbum));
  ordinaPerRate(calcolaRate(eightyNinetyAlbum));
  ordinaPerRate(calcolaRate(twothousandTwothousandTenAlbum));
  ordinaPerRate(calcolaRate(twothousandTenTodayAlbum));

  // console.log("--50-60-70-cover", fiftySixtySeventyAlbum);
  // console.log("--80-90-cover", eightyNinetyAlbum);
  // console.log("--2000-10-cover", twothousandTwothousandTenAlbum);
  // console.log("--2010-oggi-cover", twothousandTenTodayAlbum);

}

//--------- calcolo la data locale per poi inviarla al server
const currentDate = moment().format('YYYY-MM-DD');
var localDate = currentDate;

export function emptyalbumPresentiDirectoryCoverObj() {
  counterAlbum50 = 0;
  counterAlbum80 = 0;
  counterAlbum2000 = 0;
  counterAlbum2010 = 0;

  indice50start = 0;
  indice80start = 0;
  indice2000start = 0;
  indice2010start = 0;

  albumPresentiDirectoryCover = [];

  firstPopolateFirst50ChangeDay = true;
  firstPopolateFirst80ChangeDay = true;
  firstPopolateFirst2000ChangeDay = true;
  firstPopolateFirst2010ChangeDay = true;

  firstEsecutionChangeDay = true;

  arrayTemp50 = [];
  arrayTemp80 = [];
  arrayTemp2000 = [];
  arrayTemp2010 = [];

  elements80 = [];
  elements50 = [];
  elements2000 = [];
  elements2010 = [];

  albumPresentiDirectoryCoverObj = [];
  albumNonPresentiDirectoryCoverObj = [];
}

export function firstFunction(_callback) {
  arrayAlbumTotali2 = [];
  allAlbumFoundId = [];

  popolateDecadiArray(store.getState());
  _callback();
}

export async function getIdAlbumPresenti() {
  var arraiAppoggioCoverPresentiDirectory = [];
  //console.log("------dsdadasd");
  await fetch('https://testctv.herokuapp.com/AllImages', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ "dataToday": localDate })
  }).then(response =>

    response.json().then(data => ({
      data: data,
      status: response.status
    })).then(res => {
      for (var i = 0; i < res.data.id.length; i++) {
        arraiAppoggioCoverPresentiDirectory.push(res.data.id[i]);
      }
      albumPresentiDirectoryCover = arraiAppoggioCoverPresentiDirectory;
      firstFunction(function () {
        firstFunctionApp("done");
      });
    }));
}

export async function getIdAlbumPresentiFormDay(dataForm) {
  var arraiAppoggioCoverPresentiDirectoryForm = [];
  await fetch('https://testctv.herokuapp.com/AllImages', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ "dataToday": dataForm })
  }).then(response =>

    response.json().then(data => ({
      data: data,
      status: response.status
    })).then(res => {
      for (var i = 0; i < res.data.id.length; i++) {
        arraiAppoggioCoverPresentiDirectoryForm.push(res.data.id[i]);
      }
      albumPresentiDirectoryCover = arraiAppoggioCoverPresentiDirectoryForm;
      firstFunction(function () {
        localDate = dataForm;
        pushToArray(1);
      });
    }));
}

export function getAlbum(jsonDate) {
  //setto il valore degli arrai di partenza(se sono maggiori di 28 a 28 se sono minori al numero esatto)e chiamo la funzione GridAlbum per il rendering nella pagina degi stessi
  counterAlbum50 = fiftySixtySeventyAlbum.length > 28 ? 28 : fiftySixtySeventyAlbum.length;
  counterAlbum80 = eightyNinetyAlbum.length > 28 ? 28 : eightyNinetyAlbum.length;
  counterAlbum2000 = twothousandTwothousandTenAlbum.length > 28 ? 28 : twothousandTwothousandTenAlbum.length;
  counterAlbum2010 = twothousandTenTodayAlbum.length > 28 ? 28 : twothousandTenTodayAlbum.length;
  GridAlbum();
}


observableAperturaTendinaDecadi.observe(changes => {
  //ad ogni variazione della tendina scatta
});

var wi = "";

var firstArrayAlbum50 = {};
var firstArrayAlbum80 = {};
var firstArrayAlbum2000 = {};
var firstArrayAlbum2010 = {};







export function GridAlbum(dec, d) {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [dateReleaseAlbum, setDateReleaseAlbum] = useState(localDate);
  const [thisState, setThisState] = useState(null);
  const [rotd50, setRotd50] = useState(firstArrayAlbum50);
  const [rotd80, setRotd80] = useState(firstArrayAlbum80);
  const [rotd2000, setRotd2000] = useState(firstArrayAlbum2000);
  const [rotd2010, setRotd2010] = useState(firstArrayAlbum2010);
  const [rotd50ChangeDay, setRotd50ChangeDay] = useState(false);
  //--------------
  const [cA50, setCA50] = useState(0);
  const [cA80, setCA80] = useState(0);
  const [cA2000, setCA2000] = useState(0);
  const [cA2010, setCA2010] = useState(0);

  const [counterVisibleAlbum, setCounterVisibleAlbum] = useState(0);
  const [visibleAlbum, setVisibleAlbum] = useState(false);
  const [selectetTitleAlbum, setSelectetTitleAlbum] = useState();
  const [selectetTextAlbum, setSelectetTextAlbum] = useState();
  const [selectetImgAlbum, setSelectetImgAlbum] = useState("logo1.png");
  const [firstOpenPage, setFirstOpenPage] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);



  var albumForInfo = [];


  var popolateArrayCheckObservableAlbum = [];
  const observableAlbum = Observable.from(popolateArrayCheckObservableAlbum);

  observableAlbum.observe(changes => {
    setVisibleAlbum(true);
    alert("as");
  });

  function getInfoAlbum(e, data) {
    var id = $(e.target).parent().attr("relid");
    var title = "";
    var artist = "";
    var dataInfo = "";

    var idImmagineAlbumForInfo = "";
    $(".imgAlbumForInfoModal").empty();
    $(".titleAlbumModal").empty();
    $(".artistAlbumModal").empty();
    $(".containerInfoAlbumModal").css("display", "none");
    $(".ContainerVinylLoaderModal").css("display", "block");



    if ($(e.target).parent().attr("class") == "containerImg") {
      title = $(e.target).parent().parent().find('.titleAlbumForInfo').text();
      artist = $(e.target).parent().parent().find('.artistAlbumForInfo').text();
      dataInfo = $(e.target).parent().parent().find('.dataAlbumForInfo').text();
      var idAppoggio = $(e.target).parent().parent().find('.imgAlbumForInfo').attr("src");
      idImmagineAlbumForInfo = idAppoggio.split("images/")[1].split(".")[0];
    }
    else {
      title = $(e.target).parent().find('.titleAlbumForInfo').text();
      artist = $(e.target).parent().find('.artistAlbumForInfo').text();
      dataInfo = $(e.target).parent().find('.dataAlbumForInfo').text();
      var idAppoggio = $(e.target).parent().find('.imgAlbumForInfo').attr("src");
      idImmagineAlbumForInfo = idAppoggio.split("images/")[1].split(".")[0];
    }

    var updatedDate = dataInfo + "-" + localDate.substring(5, localDate.length);

    var infoAlbumTitle = {
      "title": title,
      "artist": artist,
      "dataInfo": updatedDate,
      "idImg": idImmagineAlbumForInfo,
    }

    $(".modalA").css("display", "flex");
    $('body').addClass('stop-scrolling');

    fetch('https://musicbrainz.org/ws/2/release/' + id + '?inc=artists+recordings&fmt=json', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response.ok) {
        return response.json().then(data => ({
          data: data,
          status: response.status
        })).then(res => {
          albumForInfo = setModalAlbum(res.data, infoAlbumTitle);
          ModalAlbumJs(albumForInfo, newDateForModal);
        });
      }
      throw new Error('Something went wrong');
    })
      .catch((error) => {
        console.log("errore Fetch", error)
      });
  }


  function setModalAlbum(album, infoAlbumTitle) {
    checkVisibleModal = true;
    var tracks = [];
    tracks.push({ "info": infoAlbumTitle });
    if (album.media[0]) {
      for (var i = 0; i < album.media[0].tracks.length; i++) {
        var s = {
          "title": album.media[0].tracks[i].title,
          "number": album.media[0].tracks[i].number
        }
        tracks.push(s);
      }
      var s1 = {
        "info": infoAlbumTitle,
        "tracks": tracks,
      }
      return tracks;
    }
  }

  useEffect(() => {
  }, [visibleAlbum, counterVisibleAlbum])

  //funzioni che vengono chiamate solo al caricamento della pagina, per popolare per la prima volta l'array con gli album da inserire in griglia
  function popolateFirstTimeElementsReal50(array) {
    if (firstPopolateArrayAlbum50Check == true) {
      for (var i = indice50start; i < counterAlbum50; i++) {
        arrayTemp50.push(array[i]);
        var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
          <div relId={array[i].releaseId} className="containerImg">
            <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
          </div>
          <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
          <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
          <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
        </div>
        elements50.push(divGrid);
      }
      var arrayAlbum = {
        arrAlbum: uniqueArray(arrayTemp50, it => it.id),
        divElements: uniqueArray(elements50, it => it.key)
      }
      firstArrayAlbum50 = arrayAlbum;
      firstPopolateArrayAlbum50Check = false;
    }
  }


  function popolateFirstTimeElementsReal80(array) {
    if (firstPopolateArrayAlbum80Check == true) {
      for (var i = indice80start; i < counterAlbum80; i++) {
        arrayTemp80.push(array[i]);
        var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
          <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
            <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
          </div>
          <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
          <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
          <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
        </div>
        elements80.push(divGrid);
      }
      var arrayAlbum = {
        arrAlbum: uniqueArray(arrayTemp80, it => it.id),
        divElements: uniqueArray(elements80, it => it.key)
      }
      firstArrayAlbum80 = arrayAlbum;
      firstPopolateArrayAlbum80Check = false;
    }
  }


  function popolateFirstTimeElementsReal2000(array) {
    if (firstPopolateArrayAlbum2000Check == true) {
      for (var i = indice2000start; i < counterAlbum2000; i++) {
        arrayTemp2000.push(array[i]);
        var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
          <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
            <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
          </div>
          <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
          <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
          <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
        </div>
        elements2000.push(divGrid);
      }
      var arrayAlbum = {
        arrAlbum: uniqueArray(arrayTemp2000, it => it.id),
        divElements: uniqueArray(elements2000, it => it.key)
      }
      firstArrayAlbum2000 = arrayAlbum;
      firstPopolateArrayAlbum2000Check = false;
    }
  }

  function popolateFirstTimeElementsReal2010(array) {
    if (firstPopolateArrayAlbum2010Check == true) {
      for (var i = indice2010start; i < counterAlbum2010; i++) {
        arrayTemp2010.push(array[i]);
        if (array[i] != undefined) {
          var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
            <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
              <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
            </div>
            <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
            <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
            <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
          </div>
          elements2010.push(divGrid);
        }
        else {
          continue;
        }
      }
      var arrayAlbum = {
        arrAlbum: uniqueArray(arrayTemp2010, it => it.id),
        divElements: uniqueArray(elements2010, it => it.key)
      }
      firstArrayAlbum2010 = arrayAlbum;
      firstPopolateArrayAlbum2010Check = false;
    }
  }



  //--------funzioni che vengono chiamate la prima volta per popolare le griglie al caricamento della pagina
  popolateFirstTimeElementsReal50(fiftySixtySeventyAlbum);
  popolateFirstTimeElementsReal80(eightyNinetyAlbum);
  popolateFirstTimeElementsReal2000(twothousandTwothousandTenAlbum);
  popolateFirstTimeElementsReal2010(twothousandTenTodayAlbum);

  //----funzioni che aggiungono album alla griglia
  function AddMoreContent50(dec, d) {
    $(".nocoverButtonRes").css({ "background-color": "white", "color": "black", "border": "1px solid black" });
    $(".iconArrowRotate").css("transform", "");

    const div1 = document.getElementById('bottoneAddMoreContent50');
    const decade = div1.getAttribute('value');
    var modal = document.getElementById("myModal");
    modal.style.display = "block";
    setTimeout(function () {
      modal.style.display = "none";
      if (decade == 50) {
        indice50start = counterAlbum50;
        if (counterAlbum50 + 16 <= fiftySixtySeventyAlbum.length) {
          counterTemp50 = counterAlbum50 + 16;
          for (var x = counterAlbum50; x < counterTemp50; x++) {
            globalCounterAlbum++;
            counterAlbum50++;
          }
          counterAlbum50 = counterTemp50;
          generateElementsReal50(fiftySixtySeventyAlbum);
        }
        else {
          var mancanti = fiftySixtySeventyAlbum.length - counterAlbum50;
          counterAlbum50 = counterAlbum50 + mancanti;
          generateElementsReal50(fiftySixtySeventyAlbum);
        }
        if (counterAlbum50 == fiftySixtySeventyAlbum.length) {
          $("#bottoneAddMoreContent50").css("display", "none");
        }
      }
    }, 500);
    setFirstOpenPage(false);
  }


  function AddMoreContent80(dec, d) {
    $(".nocoverButtonRes").css({ "background-color": "white", "color": "black", "border": "1px solid black" });
    $(".iconArrowRotate").css("transform", "");

    const div1 = document.getElementById('bottoneAddMoreContent80');
    const decade = div1.getAttribute('value');
    var modal = document.getElementById("myModal");
    modal.style.display = "block";
    setTimeout(function () {
      modal.style.display = "none";
      if (decade == 80) {
        indice80start = counterAlbum80;
        if (counterAlbum80 + 16 <= eightyNinetyAlbum.length) {
          counterTemp80 = counterAlbum80 + 16;
          for (var x = counterAlbum80; x < counterTemp80; x++) {
            globalCounterAlbum++;
            counterAlbum80++;
          }
          counterAlbum80 = counterTemp80;
          generateElementsReal80(eightyNinetyAlbum);
        }
        else {
          var mancanti = eightyNinetyAlbum.length - counterAlbum80;
          counterAlbum80 = counterAlbum80 + mancanti;
          generateElementsReal80(eightyNinetyAlbum);
        }
        //questo if serve per nascondere il bottone more album se sono già visibili tutti gli album
        if (counterAlbum80 == eightyNinetyAlbum.length) {
          $("#bottoneAddMoreContent80").css("display", "none");
        }
      }
    }, 500);
    setFirstOpenPage(false);
  }

  function AddMoreContent2000(dec, d) {
    $(".nocoverButtonRes").css({ "background-color": "white", "color": "black", "border": "1px solid black" });
    $(".iconArrowRotate").css("transform", "");
    const div1 = document.getElementById('bottoneAddMoreContent2000');
    const decade = div1.getAttribute('value');

    var modal = document.getElementById("myModal");
    modal.style.display = "block";
    setTimeout(function () {
      modal.style.display = "none";
      if (decade == 2000) {
        indice2000start = counterAlbum2000;
        if (counterAlbum2000 + 16 <= twothousandTwothousandTenAlbum.length) {
          counterTemp2000 = counterAlbum2000 + 16;
          for (var x = counterAlbum2000; x < counterTemp2000; x++) {
            globalCounterAlbum++;
            counterAlbum2000++;
          }
          counterAlbum2000 = counterTemp2000;
          generateElementsReal2000(twothousandTwothousandTenAlbum);
        }
        else {
          var mancanti = twothousandTwothousandTenAlbum.length - counterAlbum2000;
          counterAlbum2000 = counterAlbum2000 + mancanti;
          generateElementsReal2000(twothousandTwothousandTenAlbum);
        }
        if (counterAlbum2000 == twothousandTwothousandTenAlbum.length) {
          $("#bottoneAddMoreContent2000").css("display", "none");
        }
      }
    }, 500);
    setFirstOpenPage(false);
  }


  function AddMoreContent2010(dec, d) {
    $(".nocoverButtonRes").css({ "background-color": "white", "color": "black", "border": "1px solid black" });
    $(".iconArrowRotate").css("transform", "");

    const div1 = document.getElementById('bottoneAddMoreContent2010');
    const decade = div1.getAttribute('value');
    var modal = document.getElementById("myModal");
    modal.style.display = "block";
    setTimeout(function () {
      modal.style.display = "none";
      if (decade == 2010) {
        indice2010start = counterAlbum2010;
        if (counterAlbum2010 + 16 <= twothousandTenTodayAlbum.length) {
          counterTemp2010 = counterAlbum2010 + 16;
          for (var x = counterAlbum2010; x < counterTemp2010; x++) {
            globalCounterAlbum++;
            counterAlbum2010++;
          }
          counterAlbum2010 = counterTemp2010;
          generateElementsReal2010(twothousandTenTodayAlbum);
        }
        else {
          var mancanti = twothousandTenTodayAlbum.length - counterAlbum2010;
          counterAlbum2010 = counterAlbum2010 + mancanti;
          generateElementsReal2010(twothousandTenTodayAlbum);
        }
        if (counterAlbum2010 == twothousandTenTodayAlbum.length) {
          $("#bottoneAddMoreContent2010").css("display", "none");
        }
      }
    }, 500);
    setFirstOpenPage(false);
  }



  $(window).resize(function () {

    wi = $(window).width();
    $("#widthPaheAll").html(wi);
    var wii = parseInt($("#widthPaheAll").text());

    if (wii <= 650) {
      styleText = {
        overflow: 'auto',
        letterSpacing: '1px',
        animation: 'typing 2.0s steps(' + 40 + ', end), blink 0.4s step-end infinite, background-fade 2.0s step-end forwards',
        whiteSpace: 'nowrap',
        fontSize: '18px',
        borderRight: '2px solid orange',
        boxSizing: 'border-box',
        wordBreak: 'break-all',
        marginTop: '10px'
      }
    }
  });

  //console.log("fiftySixtySeventyAlbum = ", fiftySixtySeventyAlbum);
  //console.log("eightyNinetyAlbum = ", eightyNinetyAlbum);
  //console.log("twothousandTwothousandTenAlbum = ", twothousandTwothousandTenAlbum);
  //console.log("twothousandTenTodayAlbum = ", twothousandTenTodayAlbum);

  var checkNoCoverListOpen = false;

  var allreleaseOpen = false;
  var release50Open = false;
  var release80Open = false;
  var release2000Open = false;
  var release2010Open = false;

  function menuResponsive(action, item) {
    const menu = document.querySelector(".menu");
    const menuItems = document.querySelectorAll(".menuItem");
    const hamburger = document.querySelector(".hamburger");
    const menuIcon = document.querySelector(".menuIcon");
    const closeIcon = document.querySelector(".closeMenuRes");

    if (action == "open") {
      menu.classList.add("showMenu");
      closeIcon.style.display = "block";
      menuIcon.style.display = "none";
      $('html, body').css({
        overflow: 'hidden',
        height: '100%'
      });
    }
    if (action == "close") {
      menu.classList.remove("showMenu");
      closeIcon.style.display = "none";
      menuIcon.style.display = "block";
      $('html, body').css({
        overflow: '',
        height: ''
      });
    }
    if (action == "item") {
      menu.classList.remove("showMenu");
      closeIcon.style.display = "none";
      menuIcon.style.display = "block";
      $('html, body').css({
        overflow: '',
        height: ''
      });

      $(".nocoverButtonRes").css({ "background-color": "white", "color": "black", "border": "1px solid black" });
      $(".iconArrowRotate").css("transform", "");

      if (item == "AllRelease") {
        $(".infoTopPageAlbums").html("All Releases:");

        $("#itemResAllRelease").css("border-bottom", "1.2px solid black");
        $("#itemResRelease50").css("border-bottom", "");
        $("#itemResRelease80").css("border-bottom", "");
        $("#itemResRelease2000").css("border-bottom", "");
        $("#itemResRelease2010").css("border-bottom", "");

        $(".li-itm-All .containerAnim").css("display", "block");
        $(".li-itm-50 .containerAnim").css("display", "none");
        $(".li-itm-80 .containerAnim").css("display", "none");
        $(".li-itm-2000 .containerAnim").css("display", "none");
        $(".li-itm-2010 .containerAnim").css("display", "none");

        AllReleaseOpen();
      }
      else if (item == "Release50") {
        $(".infoTopPageAlbums").html("The '50s '60s and '70s:");

        $("#itemResAllRelease").css("border-bottom", "");
        $("#itemResRelease50").css("border-bottom", "1.2px solid black");
        $("#itemResRelease80").css("border-bottom", "");
        $("#itemResRelease2000").css("border-bottom", "");
        $("#itemResRelease2010").css("border-bottom", "");

        $(".li-itm-All .containerAnim").css("display", "none");
        $(".li-itm-50 .containerAnim").css("display", "block");
        $(".li-itm-80 .containerAnim").css("display", "none");
        $(".li-itm-2000 .containerAnim").css("display", "none");
        $(".li-itm-2010 .containerAnim").css("display", "none");

        closeAll50();
      }
      else if (item == "Release80") {
        $(".infoTopPageAlbums").html("The '80s and '90s:");

        $("#itemResAllRelease").css("border-bottom", "");
        $("#itemResRelease50").css("border-bottom", "");
        $("#itemResRelease80").css("border-bottom", "1.2px solid black");
        $("#itemResRelease2000").css("border-bottom", "");
        $("#itemResRelease2010").css("border-bottom", "");

        $(".li-itm-All .containerAnim").css("display", "none");
        $(".li-itm-50 .containerAnim").css("display", "none");
        $(".li-itm-80 .containerAnim").css("display", "block");
        $(".li-itm-2000 .containerAnim").css("display", "none");
        $(".li-itm-2010 .containerAnim").css("display", "none");

        closeAll80();
      }
      else if (item == "Release2000") {
        $(".infoTopPageAlbums").html("From 2000 to 2010:");

        $("#itemResAllRelease").css("border-bottom", "");
        $("#itemResRelease50").css("border-bottom", "");
        $("#itemResRelease80").css("border-bottom", "");
        $("#itemResRelease2000").css("border-bottom", "1.2px solid black");
        $("#itemResRelease2010").css("border-bottom", "");

        $(".li-itm-All .containerAnim").css("display", "none");
        $(".li-itm-50 .containerAnim").css("display", "none");
        $(".li-itm-80 .containerAnim").css("display", "none");
        $(".li-itm-2000 .containerAnim").css("display", "block");
        $(".li-itm-2010 .containerAnim").css("display", "none");

        closeAll2000();
      }
      else if (item == "Release2010") {
        $(".infoTopPageAlbums").html("Since 2010:");

        $("#itemResAllRelease").css("border-bottom", "");
        $("#itemResRelease50").css("border-bottom", "");
        $("#itemResRelease80").css("border-bottom", "");
        $("#itemResRelease2000").css("border-bottom", "");
        $("#itemResRelease2010").css("border-bottom", "1.2px solid black");

        $(".li-itm-All .containerAnim").css("display", "none");
        $(".li-itm-50 .containerAnim").css("display", "none");
        $(".li-itm-80 .containerAnim").css("display", "none");
        $(".li-itm-2000 .containerAnim").css("display", "none");
        $(".li-itm-2010 .containerAnim").css("display", "block");

        closeAll2010();

      }
      else if (item == "ContactUs") {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
      }
      else if (item == "MusicGraphics") {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
      }
    }
  }



  $(document).ready(function ($) {

    $(".singleAlbum").mouseover(function (e) {
      if ($(window).width() > 900) {
        var singleAlbum = "";
        if ($(e.target).parent().attr("class") == "containerImg") {
          singleAlbum = $(e.target).parent().parent();
        }
        else {
          singleAlbum = $(e.target).parent();
        }
        var id = singleAlbum.attr("relid");
      }
    });

    $(".singleAlbum").mouseleave(function (e) {
      if ($(window).width() > 900) {
        var singleAlbum = "";
        if ($(e.target).parent().attr("class") == "containerImg") {
          singleAlbum = $(e.target).parent().parent();
        }
        else {
          singleAlbum = $(e.target).parent();
        }
        var id = singleAlbum.attr("relid");
      }
    });

    $(".containerForm").css("display", "none");
    $(".containerGridAlbum").css("display", 'block');
    $(".containerGridAlbum").css("grid-template-columns", '');
    $(".containerGridAlbum").css("grid-template-areas", '');
    $(".showNoCoverButton").html("Show Table No Cover Album Found");

    //--------------menu responsive------------------------------------------------------------------
    if (firstOpenPage == true) {
      $("#itemResAllRelease").css("border-bottom", "1.2px solid black");
      $("#allArticles .containerAnim").css("display", "block");
      $("#articles50 .containerAnim").css("display", "none");
      $("#articles80 .containerAnim").css("display", "none");
      $("#articles2000 .containerAnim").css("display", "none");
      $("#articles2010 .containerAnim").css("display", "none");
    }

    //per gestire le renderizzazioni quando chiamo gridalbum dal bottone more albums
    if (d == undefined) {
      if (dec == undefined) {
        $(".allReleaseTopInfo").css("display", "block");
        $(".50TopInfo").css("display", "none");
        $(".80TopInfo").css("display", "none");
        $(".2000TopInfo").css("display", "none");
        $(".2010TopInfo").css("display", "none");
        $(".allReleaseDiv-AllYearsInfo").text("All Releases");

        $("#allArticles").css("border-bottom", "solid 0.5px black");
        $("#articles50").css("border-bottom", "");
        $("#articles80").css("border-bottom", "");
        $("#articles2000").css("border-bottom", "");
        $("#articles2010").css("border-bottom", "");


        $("#allArticles").addClass("selected-decadi");
        $("#articles50").removeClass("selected-decadi");
        $("#articles80").removeClass("selected-decadi");
        $("#articles2000").removeClass("selected-decadi");
        $("#articles2010").removeClass("selected-decadi");

        $(".li-itm-All .containerAnim").css("display", "block");
        $(".li-itm-50 .containerAnim").css("display", "none");
        $(".li-itm-80 .containerAnim").css("display", "none");
        $(".li-itm-2000 .containerAnim").css("display", "none");
        $(".li-itm-2010 .containerAnim").css("display", "none");
      }
      if (dec == "50") {
        $(".allReleaseTopInfo").css("display", "none");
        $(".50TopInfo").css("display", "block");
        $(".80TopInfo").css("display", "none");
        $(".2000TopInfo").css("display", "none");
        $(".2010TopInfo").css("display", "none");
        $(".allReleaseDiv-AllYearsInfo").text("The '50s '60s and '70s");

        $("#allArticles").css("border-bottom", "");
        $("#articles50").css("border-bottom", "solid 0.5px black");
        $("#articles80").css("border-bottom", "");
        $("#articles2000").css("border-bottom", "");
        $("#articles2010").css("border-bottom", "");

        $(".li-itm-All .containerAnim").css("display", "none");
        $(".li-itm-50 .containerAnim").css("display", "block");
        $(".li-itm-80 .containerAnim").css("display", "none");
        $(".li-itm-2000 .containerAnim").css("display", "none");
        $(".li-itm-2010 .containerAnim").css("display", "none");
      }
      if (dec == "80") {
        $(".allReleaseTopInfo").css("display", "none");
        $(".50TopInfo").css("display", "none");
        $(".80TopInfo").css("display", "block");
        $(".2000TopInfo").css("display", "none");
        $(".2010TopInfo").css("display", "none");
        $(".allReleaseDiv-AllYearsInfo").text("The '80s and '90s");

        $("#allArticles").css("border-bottom", "");
        $("#articles50").css("border-bottom", "");
        $("#articles80").css("border-bottom", "solid 0.5px black");
        $("#articles2000").css("border-bottom", "");
        $("#articles2010").css("border-bottom", "");

        $(".li-itm-All .containerAnim").css("display", "none");
        $(".li-itm-50 .containerAnim").css("display", "none");
        $(".li-itm-80 .containerAnim").css("display", "block");
        $(".li-itm-2000 .containerAnim").css("display", "none");
        $(".li-itm-2010 .containerAnim").css("display", "none");
      }
      if (dec == "2000") {
        $(".allReleaseTopInfo").css("display", "none");
        $(".50TopInfo").css("display", "none");
        $(".80TopInfo").css("display", "none");
        $(".2000TopInfo").css("display", "block");
        $(".2010TopInfo").css("display", "none");

        $("#allArticles").css("border-bottom", "");
        $("#articles50").css("border-bottom", "");
        $("#articles80").css("border-bottom", "");
        $("#articles2000").css("border-bottom", "solid 0.5px black");
        $("#articles2010").css("border-bottom", "");
        $(".allReleaseDiv-AllYearsInfo").text("From 2000 to 2010");

        $(".li-itm-All .containerAnim").css("display", "none");
        $(".li-itm-50 .containerAnim").css("display", "none");
        $(".li-itm-80 .containerAnim").css("display", "none");
        $(".li-itm-2000 .containerAnim").css("display", "block");
        $(".li-itm-2010 .containerAnim").css("display", "none");
      }
      if (dec == "2010") {
        $(".allReleaseTopInfo").css("display", "none");
        $(".50TopInfo").css("display", "none");
        $(".80TopInfo").css("display", "none");
        $(".2000TopInfo").css("display", "none");
        $(".2010TopInfo").css("display", "block");
        $(".allReleaseDiv-AllYearsInfo").text("Since 2010");

        $("#allArticles").css("border-bottom", "");
        $("#articles50").css("border-bottom", "");
        $("#articles80").css("border-bottom", "");
        $("#articles2000").css("border-bottom", "");
        $("#articles2010").css("border-bottom", "solid 0.5px black");

        $(".li-itm-All .containerAnim").css("display", "none");
        $(".li-itm-50 .containerAnim").css("display", "none");
        $(".li-itm-80 .containerAnim").css("display", "none");
        $(".li-itm-2000 .containerAnim").css("display", "none");
        $(".li-itm-2010 .containerAnim").css("display", "block");
      }
    }
    if (d == "dec") {
    }

    $("#allReleaseTableNoCover").attr('style', 'display: none');
    $("#50TableNoCover").css("display", "none");
    $("#80TableNoCover").css("display", "none");
    $("#2000TableNoCover").css("display", "none");
    $("#2010TableNoCover").css("display", "none");

    //tabellaNoCover
    $(".containerTableNoCover").css("display", "none");
  })

  const divStile = {
    color: 'black',
    backgroundColor: 'white',
    border: 'solid 1.7px black',
    borderRadius: '20px'
  };

  //funzioni per fixare il bug lato mobile sul reset degli indici
  function generateDivForGridAlbum50BugFix(array, counterAlbum50, indice50start) {
    if (counterAlbum50 + 16 > fiftySixtySeventyAlbum.length) {
      var mancanti = fiftySixtySeventyAlbum.length - counterAlbum50;
      counterAlbum50 = counterAlbum50 + mancanti;
      $("#bottoneAddMoreContent50").css("display", "none");
    }
    for (var i = indice50start; i < counterAlbum50; i++) {
      arrayTemp50.push(array[i]);
      var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
        <div className="containerImg" relId={array[i].releaseId}>
          <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
        </div>
        <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
        <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
        <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
      </div>
      elements50.push(divGrid);
    }
    var arrayAlbum = {
      arrAlbum: uniqueArray(arrayTemp50, it => it.id),
      divElements: uniqueArray(elements50, it => it.key)
    }
    return arrayAlbum;
  }


  function generateDivForGridAlbum80BugFix(array, counterAlbum80, indice80start) {
    if (counterAlbum80 + 16 > eightyNinetyAlbum.length) {
      var mancanti = eightyNinetyAlbum.length - counterAlbum80;
      counterAlbum80 = counterAlbum80 + mancanti;
      $("#bottoneAddMoreContent80").css("display", "none");
    }
    for (var i = indice80start; i < counterAlbum80; i++) {
      arrayTemp80.push(array[i]);
      var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
        <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
          <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
        </div>
        <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
        <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
        <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
      </div>
      elements80.push(divGrid);
    }
    var arrayAlbum = {
      arrAlbum: uniqueArray(arrayTemp80, it => it.id),
      divElements: uniqueArray(elements80, it => it.key)
    }
    return arrayAlbum;
  }


  function generateDivForGridAlbum2000BugFix(array, counterAlbum2000, indice2000start) {
    if (counterAlbum2000 + 16 > twothousandTwothousandTenAlbum.length) {
      var mancanti = twothousandTwothousandTenAlbum.length - counterAlbum2000;
      counterAlbum2000 = counterAlbum2000 + mancanti;
      $("#bottoneAddMoreContent2000").css("display", "none");
    }
    for (var i = indice2000start; i < counterAlbum2000; i++) {
      arrayTemp2000.push(array[i]);
      var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
        <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
          <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
        </div>
        <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
        <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
        <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
      </div>
      elements2000.push(divGrid);
    }
    var arrayAlbum = {
      arrAlbum: uniqueArray(arrayTemp2000, it => it.id),
      divElements: uniqueArray(elements2000, it => it.key)
    }
    return arrayAlbum;
  }



  function generateDivForGridAlbum2010BugFix(array, counterAlbum2010, indice2010start) {
    if (counterAlbum2010 + 16 > twothousandTenTodayAlbum.length) {
      var mancanti = twothousandTenTodayAlbum.length - counterAlbum2010;
      counterAlbum2010 = counterAlbum2010 + mancanti;
      $("#bottoneAddMoreContent2010").css("display", "none");
    }
    for (var i = indice2010start; i < counterAlbum2010; i++) {
      arrayTemp2010.push(array[i]);
      if (array[i] != undefined) {
        var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
          <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
            <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
          </div>
          <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
          <div className="flow-text artistAlbumForInfo">{array[i].Artist}</div>
          <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
        </div>
        elements2010.push(divGrid);
      }
      else {
        continue;
      }
    }
    var arrayAlbum = {
      arrAlbum: uniqueArray(arrayTemp2010, it => it.id),
      divElements: uniqueArray(elements2010, it => it.key)
    }
    return arrayAlbum;
  }

  function generateElementsReal50(array) {
    var arrayAlbumPRE = rotd50.divElements.length;
    for (var i = indice50start; i < counterAlbum50; i++) {
      arrayTemp50.push(array[i]);
      var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
        <div className="containerImg" relId={array[i].releaseId}>
          <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
        </div>
        <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
        <div className="flow-text artistAlbumForInfo">{array[i].Artist}</div>
        <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
      </div>
      elements50.push(divGrid);
    }
    var arrayAlbum = {
      arrAlbum: uniqueArray(arrayTemp50, it => it.id),
      divElements: uniqueArray(elements50, it => it.key)
    }
    //controllo per le versioni mobile sul problema del reset degli indici
    var controlloClick = false;
    if ($(window).width() < 900) {
      for (var i = 0; controlloClick == false; i++) {
        if (i == 20) {
          controlloClick = true;
        }
        if (arrayAlbumPRE == arrayAlbum.divElements.length) {
          var newCounterAlbum = arrayAlbum.divElements.length + 16;
          var newIndiceStart = arrayAlbum.divElements.length;
          var newLengthDiv = generateDivForGridAlbum50BugFix(array, newCounterAlbum, newIndiceStart);
          if (newLengthDiv.divElements.length > arrayAlbumPRE) {
            arrayAlbum = newLengthDiv;
            controlloClick = true;
          }
        }
        else {
          controlloClick = true;
        }
      }
    }
    setRotd50(arrayAlbum);
  }

  function generateElementsReal80(array) {
    var arrayAlbumPRE = rotd80.divElements.length;
    for (var i = indice80start; i < counterAlbum80; i++) {
      arrayTemp80.push(array[i]);
      var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
        <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
          <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
        </div>
        <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
        <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
        <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
      </div>
      elements80.push(divGrid);
    }
    var arrayAlbum = {
      arrAlbum: uniqueArray(arrayTemp80, it => it.id),
      divElements: uniqueArray(elements80, it => it.key)
    }
    //controllo per le versioni mobile sul problema del reset degli indici
    var controlloClick = false;
    if ($(window).width() < 900) {
      for (var i = 0; controlloClick == false; i++) {
        if (i == 20) {
          controlloClick = true;
        }
        if (arrayAlbumPRE == arrayAlbum.divElements.length) {
          var newCounterAlbum = arrayAlbum.divElements.length + 16;
          var newIndiceStart = arrayAlbum.divElements.length;
          var newLengthDiv = generateDivForGridAlbum80BugFix(array, newCounterAlbum, newIndiceStart);
          if (newLengthDiv.divElements.length > arrayAlbumPRE) {
            arrayAlbum = newLengthDiv;
            controlloClick = true;
          }
        }
        else {
          controlloClick = true;
        }
      }
    }
    setRotd80(arrayAlbum);
  }


  function generateElementsReal2000(array) {
    var arrayAlbumPRE = rotd2000.divElements.length;
    for (var i = indice2000start; i < counterAlbum2000; i++) {
      arrayTemp2000.push(array[i]);
      var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
        <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
          <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
        </div>
        <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
        <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
        <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
      </div>
      elements2000.push(divGrid);
    }
    var arrayAlbum = {
      arrAlbum: uniqueArray(arrayTemp2000, it => it.id),
      divElements: uniqueArray(elements2000, it => it.key)
    }

    //controllo per le versioni mobile sul problema del reset degli indici
    var controlloClick = false;
    if ($(window).width() < 900) {
      for (var i = 0; controlloClick == false; i++) {
        if (i == 20) {
          controlloClick = true;
        }
        if (arrayAlbumPRE == arrayAlbum.divElements.length) {
          var newCounterAlbum = arrayAlbum.divElements.length + 16;
          var newIndiceStart = arrayAlbum.divElements.length;
          var newLengthDiv = generateDivForGridAlbum2000BugFix(array, newCounterAlbum, newIndiceStart);
          if (newLengthDiv.divElements.length > arrayAlbumPRE) {
            arrayAlbum = newLengthDiv;
            controlloClick = true;
          }
        }
        else {
          controlloClick = true;
        }
      }
    }
    setRotd2000(arrayAlbum);
  }

  function generateElementsReal2010(array) {
    var arrayAlbumPRE = rotd2010.divElements.length;
    for (var i = indice2010start; i < counterAlbum2010; i++) {
      arrayTemp2010.push(array[i]);
      if (array[i] != undefined) {
        var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
          <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
            <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + localDate} />
          </div>
          <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
          <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
          <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
        </div>
        elements2010.push(divGrid);
      }
      else {
        continue;
      }
    }
    var arrayAlbum = {
      arrAlbum: uniqueArray(arrayTemp2010, it => it.id),
      divElements: uniqueArray(elements2010, it => it.key)
    }
    //console.log("||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||")
    //console.log("arrayAlbum2010", arrayAlbum);
    //console.log("arrayAlbum2010-lenght", arrayAlbum.divElements.length);
    //console.log("counter album", counterAlbum2010);
    //console.log("indiceStart", indice2010start);

    //controllo per le versioni mobile sul problema sìdel reset degli indici
    var controlloClick = false;
    if ($(window).width() < 900) {
      for (var i = 0; controlloClick == false; i++) {
        if (i == 20) {
          controlloClick = true;
        }
        if (arrayAlbumPRE == arrayAlbum.divElements.length) {
          var newCounterAlbum = arrayAlbum.divElements.length + 16;
          var newIndiceStart = arrayAlbum.divElements.length;
          var newLengthDiv = generateDivForGridAlbum2010BugFix(array, newCounterAlbum, newIndiceStart);
          if (newLengthDiv.divElements.length > arrayAlbumPRE) {
            arrayAlbum = newLengthDiv;
            controlloClick = true;
          }
        }
        else {
          controlloClick = true;
        }
      }
    }
    setRotd2010(arrayAlbum);
  }

  function changeColor() {
    $(".moreAlbum").css("background-color", "black");
    $(".moreAlbum").css("color", "white");
  }

  function resetColor() {
    $(".moreAlbum").css("background-color", "white");
    $(".moreAlbum").css("color", "black");
  }

  function changeColorNoCoverButton() {
    $(".showNoCoverButton").css("background-color", "black");
    $(".showNoCoverButton").css("color", "white");
    $(".showNoCoverButton").css("border", "solid 0.5px black");
  }

  function resetColorNoCoverButton() {
    $(".showNoCoverButton").css("background-color", "white");
    $(".showNoCoverButton").css("color", "black");
    $(".showNoCoverButton").css("border", "solid 1px black");
  }

  function noCoverAlbumSet(decade) {
    if (decade == "All") {
      if (checkNoCoverListOpen == false) {
        $(".containerTableNoCover").attr('style', 'display: block !important');
        $(".showNoCoverButton").html("Hide Table No Cover Album Found");
        $(".nocoverButtonRes").css({ "background-color": "black", "color": "white", "border": "1px solid white" });
        $(".iconArrowRotate").css("transform", "rotate(180deg)");
        checkNoCoverListOpen = true;
      }
      else {
        $(".containerTableNoCover").attr('style', 'display: none !important');
        $(".showNoCoverButton").html("Show Table No Cover Album Found");
        $(".nocoverButtonRes").css({ "background-color": "white", "color": "black", "border": "1px solid black" });
        $(".iconArrowRotate").css("transform", "");
        checkNoCoverListOpen = false;
      }
    }
    if (decade == "50") {
      if (checkNoCoverListOpen == false) {
        $(".containerTableNoCover50").css("display", "block");
        $(".showNoCoverButton").html("Hide Table No Cover Album Found");

        $(".nocoverButtonRes").css({ "background-color": "black", "color": "white", "border": "1px solid white" });
        $(".iconArrowRotate").css("transform", "rotate(180deg)");
        checkNoCoverListOpen = true;
      }
      else {
        $(".containerTableNoCover50").css("display", "none");
        $(".showNoCoverButton").html("Show Table No Cover Album Found");

        $(".nocoverButtonRes").css({ "background-color": "white", "color": "black", "border": "1px solid black" });
        $(".iconArrowRotate").css("transform", "");
        checkNoCoverListOpen = false;
      }
    }
    if (decade == "80") {
      if (checkNoCoverListOpen == false) {
        $(".containerTableNoCover80").css("display", "block");
        $(".showNoCoverButton").html("Hide Table No Cover Album Found");

        $(".nocoverButtonRes").css({ "background-color": "black", "color": "white", "border": "1px solid white" });
        $(".iconArrowRotate").css("transform", "rotate(180deg)");
        checkNoCoverListOpen = true;
      }
      else {
        $(".containerTableNoCover80").css("display", "none");
        $(".showNoCoverButton").html("Show Table No Cover Album Found");

        $(".nocoverButtonRes").css({ "background-color": "white", "color": "black", "border": "1px solid black" });
        $(".iconArrowRotate").css("transform", "");
        checkNoCoverListOpen = false;
      }
    }
    if (decade == "2000") {
      if (checkNoCoverListOpen == false) {
        $(".containerTableNoCover2000").css("display", "block");
        $(".showNoCoverButton").html("Hide Table No Cover Album Found");

        $(".nocoverButtonRes").css({ "background-color": "black", "color": "white", "border": "1px solid white" });
        $(".iconArrowRotate").css("transform", "rotate(180deg)");
        checkNoCoverListOpen = true;
      }
      else {
        $(".containerTableNoCover2000").css("display", "none");
        $(".showNoCoverButton").html("Show Table No Cover Album Found");

        $(".nocoverButtonRes").css({ "background-color": "white", "color": "black", "border": "1px solid black" });
        $(".iconArrowRotate").css("transform", "");
        checkNoCoverListOpen = false;
      }
    }
    if (decade == "2010") {
      if (checkNoCoverListOpen == false) {
        $(".containerTableNoCover2010").css("display", "block");
        $(".showNoCoverButton").html("Hide Table No Cover Album Found");

        $(".nocoverButtonRes").css({ "background-color": "black", "color": "white", "border": "1px solid white" });
        $(".iconArrowRotate").css("transform", "rotate(180deg)");
        checkNoCoverListOpen = true;
      }
      else {
        $(".containerTableNoCover2010").css("display", "none");
        $(".showNoCoverButton").html("Show Table No Cover Album Found");

        $(".nocoverButtonRes").css({ "background-color": "white", "color": "black", "border": "1px solid black" });
        $(".iconArrowRotate").css("transform", "");
        checkNoCoverListOpen = false;
      }
    }
  }

  //funzioni per gestire i filtri delle decadi in recordofTheDay
  function AllReleaseOpen() {
    $(".iconArrowRotate").css("transform", "");
    allreleaseOpen = true;
    $(".allReleaseDiv-AllYearsInfo").text("All Releases");
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    $(".containerRelease80").css("display", "block");
    $(".containerRelease2000").css("display", "block");
    $(".containerRelease2010").css("display", "block");
    $(".containerRelease50").css("display", "block");
    //
    $(".allReleaseTopInfo").css("display", "block");
    $(".50TopInfo").css("display", "none");
    $(".80TopInfo").css("display", "none");
    $(".2000TopInfo").css("display", "none");
    $(".2010TopInfo").css("display", "none");

    $("#allReleaseTableNoCover").attr('style', 'display: block !important');
    $("#50TableNoCover").css("display", "none");
    $("#80TableNoCover").css("display", "none");
    $("#2000TableNoCover").css("display", "none");
    $("#2010TableNoCover").css("display", "none");

    checkNoCoverListOpen = false;
    $(".containerTableNoCover").css("display", "none");
    $(".containerTableNoCover50").css("display", "none");
    $(".containerTableNoCover80").css("display", "none");
    $(".containerTableNoCover2000").css("display", "none");
    $(".containerTableNoCover2010").css("display", "none");
    $(".showNoCoverButton").html("Show Table No Cover Album Found");


    $("#allArticles").css("border-bottom", "solid 0.5px black");
    $("#articles50").css("border-bottom", "");
    $("#articles80").css("border-bottom", "");
    $("#articles2000").css("border-bottom", "");
    $("#articles2010").css("border-bottom", "");

    $("#allArticles").addClass("selected-decadi");
    $("#articles50").removeClass("selected-decadi");
    $("#articles80").removeClass("selected-decadi");
    $("#articles2000").removeClass("selected-decadi");
    $("#articles2010").removeClass("selected-decadi");

    $("#allArticles .containerAnim").css("display", "block");
    $("#articles50 .containerAnim").css("display", "none");
    $("#articles80 .containerAnim").css("display", "none");
    $("#articles2000 .containerAnim").css("display", "none");
    $("#articles2010 .containerAnim").css("display", "none");
  }

  function closeAll50() {
    $(".iconArrowRotate").css("transform", "");
    release50Open = true;
    $(".allReleaseDiv-AllYearsInfo").text("The '50s '60s and '70s");
    $('html, body').animate({ scrollTop: 0 }, 100);
    $(".containerRelease80").css("display", "none");
    $(".containerRelease2000").css("display", "none");
    $(".containerRelease2010").css("display", "none");
    //
    $(".containerRelease50").css("display", "block");
    //
    $(".allReleaseTopInfo").css("display", "none");
    $(".50TopInfo").css("display", "block");
    $(".80TopInfo").css("display", "none");
    $(".2000TopInfo").css("display", "none");
    $(".2010TopInfo").css("display", "none");

    $("#allReleaseTableNoCover").attr('style', 'display: none');
    $("#50TableNoCover").css("display", "block");
    $("#80TableNoCover").css("display", "none");
    $("#2000TableNoCover").css("display", "none");
    $("#2010TableNoCover").css("display", "none");

    checkNoCoverListOpen = false;
    $(".containerTableNoCover").css("display", "none");
    $(".containerTableNoCover50").css("display", "none");
    $(".containerTableNoCover80").css("display", "none");
    $(".containerTableNoCover2000").css("display", "none");
    $(".containerTableNoCover2010").css("display", "none");
    $(".showNoCoverButton").html("Show Table No Cover Album Found");

    $("#allArticles").css("border-bottom", "");
    $("#articles50").css("border-bottom", "solid 0.5px black");
    $("#articles80").css("border-bottom", "");
    $("#articles2000").css("border-bottom", "");
    $("#articles2010").css("border-bottom", "");

    $("#articles50").addClass("selected-decadi");
    $("#articles80").removeClass("selected-decadi");
    $("#articles2000").removeClass("selected-decadi");
    $("#articles2010").removeClass("selected-decadi");
    $("#allArticles").removeClass("selected-decadi");

    $("#allArticles .containerAnim").css("display", "none");
    $("#articles50 .containerAnim").css("display", "block");
    $("#articles80 .containerAnim").css("display", "none");
    $("#articles2000 .containerAnim").css("display", "none");
    $("#articles2010 .containerAnim").css("display", "none");

  }
  function closeAll80() {
    $(".iconArrowRotate").css("transform", "");
    release80Open = true;
    $(".allReleaseDiv-AllYearsInfo").text("The '80s and '90s");
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    $(".containerRelease50").css("display", "none");
    $(".containerRelease2000").css("display", "none");
    $(".containerRelease2010").css("display", "none");
    //
    $(".containerRelease80").css("display", "block");
    //
    $(".allReleaseTopInfo").css("display", "none");
    $(".50TopInfo").css("display", "none");
    $(".80TopInfo").css("display", "block");
    $(".2000TopInfo").css("display", "none");
    $(".2010TopInfo").css("display", "none");

    $("#allReleaseTableNoCover").attr('style', 'display: none');
    $("#50TableNoCover").css("display", "none");
    $("#80TableNoCover").css("display", "block");
    $("#2000TableNoCover").css("display", "none");
    $("#2010TableNoCover").css("display", "none");

    checkNoCoverListOpen = false;
    $(".containerTableNoCover").css("display", "none");
    $(".containerTableNoCover50").css("display", "none");
    $(".containerTableNoCover80").css("display", "none");
    $(".containerTableNoCover2000").css("display", "none");
    $(".containerTableNoCover2010").css("display", "none");
    $(".showNoCoverButton").html("Show Table No Cover Album Found");

    $("#allArticles").css("border-bottom", "");
    $("#articles50").css("border-bottom", "");
    $("#articles80").css("border-bottom", "solid 0.5px black");
    $("#articles2000").css("border-bottom", "");
    $("#articles2010").css("border-bottom", "");

    $("#articles80").addClass("selected-decadi");
    $("#articles50").removeClass("selected-decadi");
    $("#articles2000").removeClass("selected-decadi");
    $("#articles2010").removeClass("selected-decadi");
    $("#allArticles").removeClass("selected-decadi");

    $("#allArticles .containerAnim").css("display", "none");
    $("#articles50 .containerAnim").css("display", "none");
    $("#articles80 .containerAnim").css("display", "block");
    $("#articles2000 .containerAnim").css("display", "none");
    $("#articles2010 .containerAnim").css("display", "none");


  }
  function closeAll2000() {
    $(".iconArrowRotate").css("transform", "");
    release2000Open = true;
    $(".allReleaseDiv-AllYearsInfo").text("From 2000 to 2010");
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    $(".containerRelease80").css("display", "none");
    $(".containerRelease50").css("display", "none");
    $(".containerRelease2010").css("display", "none");
    //
    $(".containerRelease2000").css("display", "block");
    //
    $(".allReleaseTopInfo").css("display", "none");
    $(".50TopInfo").css("display", "none");
    $(".80TopInfo").css("display", "none");
    $(".2000TopInfo").css("display", "block");
    $(".2010TopInfo").css("display", "none");

    $("#allReleaseTableNoCover").attr('style', 'display: none');
    $("#50TableNoCover").css("display", "none");
    $("#80TableNoCover").css("display", "none");
    $("#2000TableNoCover").css("display", "block");
    $("#2010TableNoCover").css("display", "none");

    checkNoCoverListOpen = false;
    $(".containerTableNoCover").css("display", "none");
    $(".containerTableNoCover50").css("display", "none");
    $(".containerTableNoCover80").css("display", "none");
    $(".containerTableNoCover2000").css("display", "none");
    $(".containerTableNoCover2010").css("display", "none");
    $(".showNoCoverButton").html("Show Table No Cover Album Found");

    $("#allArticles").css("border-bottom", "");
    $("#articles50").css("border-bottom", "");
    $("#articles80").css("border-bottom", "");
    $("#articles2000").css("border-bottom", "solid 0.5px black");
    $("#articles2010").css("border-bottom", "");

    $("#articles2000").addClass("selected-decadi");
    $("#articles80").removeClass("selected-decadi");
    $("#articles50").removeClass("selected-decadi");
    $("#articles2010").removeClass("selected-decadi");
    $("#allArticles").removeClass("selected-decadi");

    $("#allArticles .containerAnim").css("display", "none");
    $("#articles50 .containerAnim").css("display", "none");
    $("#articles80 .containerAnim").css("display", "none");
    $("#articles2000 .containerAnim").css("display", "block");
    $("#articles2010 .containerAnim").css("display", "none");

  }
  function closeAll2010() {
    $(".iconArrowRotate").css("transform", "");
    release2010Open = true;
    $(".allReleaseDiv-AllYearsInfo").text("Since 2010");
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    $(".containerRelease80").css("display", "none");
    $(".containerRelease2000").css("display", "none");
    $(".containerRelease50").css("display", "none");
    //
    $(".containerRelease2010").css("display", "block");
    //
    $(".allReleaseTopInfo").css("display", "none");
    $(".50TopInfo").css("display", "none");
    $(".80TopInfo").css("display", "none");
    $(".2000TopInfo").css("display", "none");
    $(".2010TopInfo").css("display", "block");

    $("#allReleaseTableNoCover").attr('style', 'display: none');
    $("#50TableNoCover").css("display", "none");
    $("#80TableNoCover").css("display", "none");
    $("#2000TableNoCover").css("display", "none");
    $("#2010TableNoCover").css("display", "block");

    checkNoCoverListOpen = false;
    $(".containerTableNoCover").css("display", "none");
    $(".containerTableNoCover50").css("display", "none");
    $(".containerTableNoCover80").css("display", "none");
    $(".containerTableNoCover2000").css("display", "none");
    $(".containerTableNoCover2010").css("display", "none");
    $(".showNoCoverButton").html("Show Table No Cover Album Found");

    $("#allArticles").css("border-bottom", "");
    $("#articles50").css("border-bottom", "");
    $("#articles80").css("border-bottom", "");
    $("#articles2000").css("border-bottom", "");
    $("#articles2010").css("border-bottom", "solid 0.5px black");

    $("#articles2010").addClass("selected-decadi");
    $("#articles80").removeClass("selected-decadi");
    $("#articles2000").removeClass("selected-decadi");
    $("#articles50").removeClass("selected-decadi");
    $("#allArticles").removeClass("selected-decadi");

    $("#allArticles .containerAnim").css("display", "none");
    $("#articles50 .containerAnim").css("display", "none");
    $("#articles80 .containerAnim").css("display", "none");
    $("#articles2000 .containerAnim").css("display", "none");
    $("#articles2010 .containerAnim").css("display", "block");
  }

  //--------------section info------------------------------

  var counterNArrayStringa = 0;
  var state = store.getState();

  async function popolateFirstTimeElementsReal50ChangeDay(array, newDate) {
    if (firstPopolateFirst50ChangeDay == true) {
      for (var i = indice50start; i < counterAlbum50; i++) {
        arrayTemp50.push(array[i]);
        var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
          <div relId={array[i].releaseId} className="containerImg">
            <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + newDate} />
          </div>
          <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
          <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
          <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
        </div>
        elements50.push(divGrid);
      }

      var arrayAlbum = {
        arrAlbum: uniqueArray(arrayTemp50, it => it.id),
        divElements: uniqueArray(elements50, it => it.key)
      }
      firstArrayAlbum50 = arrayAlbum;
      firstPopolateFirst50ChangeDay = false;
    }
  }

  function popolateFirstTimeElementsReal80ChangeDay(array, newDate) {
    if (firstPopolateFirst80ChangeDay == true) {
      for (var i = indice80start; i < counterAlbum80; i++) {
        arrayTemp80.push(array[i]);
        var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
          <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
            <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + newDate} />
          </div>
          <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
          <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
          <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
        </div>
        elements80.push(divGrid);
      }
      var arrayAlbum = {
        arrAlbum: uniqueArray(arrayTemp80, it => it.id),
        divElements: uniqueArray(elements80, it => it.key)
      }
      firstArrayAlbum80 = arrayAlbum;
      firstPopolateFirst80ChangeDay = false;
    }
  }

  function popolateFirstTimeElementsReal2000ChangeDay(array, newDate) {
    if (firstPopolateFirst2000ChangeDay == true) {
      for (var i = indice2000start; i < counterAlbum2000; i++) {
        arrayTemp2000.push(array[i]);
        var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
          <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
            <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + newDate} />
          </div>
          <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
          <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
          <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
        </div>
        elements2000.push(divGrid);
      }
      var arrayAlbum = {
        arrAlbum: uniqueArray(arrayTemp2000, it => it.id),
        divElements: uniqueArray(elements2000, it => it.key)
      }
      firstArrayAlbum2000 = arrayAlbum;
      firstPopolateFirst2000ChangeDay = false;
    }
  }

  function popolateFirstTimeElementsReal2010ChangeDay(array, newDate) {
    if (firstPopolateFirst2010ChangeDay == true) {
      for (var i = indice2010start; i < counterAlbum2010; i++) {
        arrayTemp2010.push(array[i]);
        if (array[i] != undefined) {
          var divGrid = <div onClick={(e) => getInfoAlbum(e)} key={array[i].id} className="singleAlbum" relId={array[i].releaseId}>
            <div key={array[i].id + " img"} className="containerImg" relId={array[i].releaseId}>
              <img className="responsive-img imgAlbumForInfo" src={urlTestCtv + "/images/" + array[i].id + ".jpeg&" + newDate} />
            </div>
            <div className="flow-text titleAlbumForInfo">{formatStringGriglia(array[i].Title)}</div>
            <div className="flow-text artistAlbumForInfo">{formatStringGriglia(array[i].Artist)}</div>
            <div className="flow-text dataAlbumForInfo">{array[i].Date.substring(0, 4)}</div>
          </div>
          elements2010.push(divGrid);
        }
        else {
          continue;
        }
      }
      var arrayAlbum = {
        arrAlbum: uniqueArray(arrayTemp2010, it => it.id),
        divElements: uniqueArray(elements2010, it => it.key)
      }
      firstArrayAlbum2010 = arrayAlbum;
      firstPopolateFirst2010ChangeDay = false;
    }
  }

  // Subscribe to the subject to react to changes
  arraySubject.subscribe((item) => {
    if (firstEsecutionChangeDay == true) {
      AllReleaseOpen();
      var modal = document.getElementById("myModal");
      modal.style.display = "none";
      var nDate = store.getState()[0].Date.substring(store.getState()[0].Date - 5);

      firstArrayAlbum50 = {};
      firstArrayAlbum80 = {};
      firstArrayAlbum2000 = {};
      firstArrayAlbum2010 = {};

      counterAlbum50 = fiftySixtySeventyAlbum.length > 28 ? 28 : fiftySixtySeventyAlbum.length;
      counterAlbum80 = eightyNinetyAlbum.length > 28 ? 28 : eightyNinetyAlbum.length;
      counterAlbum2000 = twothousandTwothousandTenAlbum.length > 28 ? 28 : twothousandTwothousandTenAlbum.length;
      counterAlbum2010 = twothousandTenTodayAlbum.length > 28 ? 28 : twothousandTenTodayAlbum.length;

      newDateForModal = "";
      newDateForModal = localDate;

      popolateFirstTimeElementsReal50ChangeDay(fiftySixtySeventyAlbum, nDate);
      popolateFirstTimeElementsReal80ChangeDay(eightyNinetyAlbum, nDate);
      popolateFirstTimeElementsReal2000ChangeDay(twothousandTwothousandTenAlbum, nDate);
      popolateFirstTimeElementsReal2010ChangeDay(twothousandTenTodayAlbum, nDate);

      setRotd50(firstArrayAlbum50);
      setRotd80(firstArrayAlbum80);
      setRotd2000(firstArrayAlbum2000);
      setRotd2010(firstArrayAlbum2010);

      $(".infoTopPageAlbums").html("All Releases:");
      $("#itemResRelease50").css("border-bottom", "");
      $("#itemResRelease80").css("border-bottom", "");
      $("#itemResRelease2000").css("border-bottom", "");
      $("#itemResRelease2010").css("border-bottom", "");

      $("#bottoneAddMoreContent50").css("display", "block");
      $("#bottoneAddMoreContent80").css("display", "block");
      $("#bottoneAddMoreContent2000").css("display", "block");
      $("#bottoneAddMoreContent2010").css("display", "block");

      popolateArrayChangeDay = [];
      firstEsecutionChangeDay = false;
      $(".myModal-infosite").css("display", "none");
    }
    else {
      var modal = document.getElementById("myModal");
      modal.style.display = "none";
      setRotd50(firstArrayAlbum50);
      setRotd80(firstArrayAlbum80);
      setRotd2000(firstArrayAlbum2000);
      setRotd2010(firstArrayAlbum2010);
      $(".myModal-infosite").css("display", "none");
    }
  });

  function removeMenuBar() {
    $(".menuBarApp").css("display", "none");
  }

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
  const newDate = date.substring(0, date.length - 5).replace("/", ".");
  const newDateRes = date.substring(0, date.length - 5).replace("/", "-");

  const dateRes = new Date();
  const resultDateRes = dateRes.toLocaleDateString("locales", { // you can use undefined as first argument
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  if ($(window).width() < 900) {
    return (
      <section className="about">
        <WidthPage />
        <div id="about-title" className="about-title">
        </div>
        <ul className="menu">
          <div className="containerMenuResponsive">
            <div className="containerIconCloseAndArticleText">
              <div className="itemMenuResponsive">Vibez</div>
              <div className="closeMenuRes">
                <Picture onClick={() => menuResponsive("close")} className="closeMenuResponsive" src={require("../resources/icons8-close-30white.png").default} />
              </div>
            </div>
            <li className='li-items-menu-responsive li-itm-All'><a id="itemResAllRelease" onClick={() => menuResponsive("item", "AllRelease")} className="menuItem" href="#">All Releases</a> <EqAnim /></li>
            <li className='li-items-menu-responsive li-itm-50'><a id="itemResRelease50" onClick={() => menuResponsive("item", "Release50")} className="menuItem" href="#">The '50s '60s and '70s</a><EqAnim /></li>
            <li className='li-items-menu-responsive li-itm-80'><a id="itemResRelease80" onClick={() => menuResponsive("item", "Release80")} className="menuItem" href="#">The '80s and '90s</a><EqAnim /></li>
            <li className='li-items-menu-responsive li-itm-2000'><a id="itemResRelease2000" onClick={() => menuResponsive("item", "Release2000")} className="menuItem" href="#">From 2000 to 2010</a><EqAnim /></li>
            <li className='li-items-menu-responsive li-itm-2010'><a id="itemResRelease2010" onClick={() => menuResponsive("item", "Release2010")} className="menuItem" href="#">Since 2010</a><EqAnim /></li>
            <div className="itemMenuResponsiveVibez">Vibez</div>
          </div>
        </ul>
        <div className="about-pages">
          <div className="ctvhomecontainer">
            <Picture className="ctvHome rotdRes-bigTitle" src={require("../resources/LLL.png").default} />
          </div>
          <Row>
            <Col span={20} style={mStyleResponsive1}>
              <div className="infoTopPageAlbums">
                All Releases:
              </div>
            </Col>
            <Col span={4} style={mStyleResponsive}>
              <button className="hamburger">
                <i onClick={() => menuResponsive("open")} className="menuIcon material-icons">
                  <Picture className="iconBurgherOpen" src={require("../resources/icons8-menu-50.png").default} />
                </i>
              </button>
            </Col>
          </Row>
          <div className='infoDateTodayRes'>{localDate.substring(5, 11).replace("-", "/")}</div>
          {/* <HorizontallyScrollingText text={"The day " + localDate.substring(5, 11).replace("-", "/") + " from the 50s have been released " + state.length + " Music Albums"} /> */}
          <div className='div-scrolling-text-responsive'>
          <Marquee gradient={false} speed={50} direction="right">
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className="scrolling-text-responsive" style={{ display: 'inline-block', marginRight: '10px' }}>
                  {" - The day " + localDate.substring(5, 11).replace("-", "/") + " from the 50s have been released " + state.length + " Music Albums "}
                </div>
              ))}
            </Marquee>
          </div>
          <div className='containerFormDayMobile'><FormDay /></div>
          {/* {<ModalInfoSite />} */}
          {<ModalModal2 />}
          {<ModalAlbumJs album={albumForInfo} />}
          <div className='containerRelease50 contRel'>
            <h2 className='titleDecade'>The '50s '60s and '70s</h2>
            {<NestedGrid state={rotd50} />}
            {fiftySixtySeventyAlbum.length > 28 ? <Button id="bottoneAddMoreContent50" className="moreAlbum" onMouseOver={changeColor} onMouseLeave={resetColor} onClick={() => AddMoreContent50("50", "dec")} value={50} style={divStile}> More Albums </Button> : <div></div>}
          </div>
          <div className='containerRelease80 contRel'>
            <h2 className='titleDecade'>The '80s and '90s</h2>
            {<NestedGrid state={rotd80} />}
            {eightyNinetyAlbum.length > 28 ? <Button id="bottoneAddMoreContent80" className="moreAlbum" onMouseOver={changeColor} onMouseLeave={resetColor} onClick={() => AddMoreContent80("80", "dec")} value={80} style={divStile}> More Albums </Button> : <div></div>}
          </div>
          <div className='containerRelease2000 contRel'>
            <h2 className='titleDecade'>From 2000 to 2010</h2>
            {<NestedGrid state={rotd2000} />}
            {twothousandTwothousandTenAlbum.length > 28 ? <Button id="bottoneAddMoreContent2000" className="moreAlbum" onMouseOver={changeColor} onMouseLeave={resetColor} onClick={() => AddMoreContent2000("2000", "dec")} value={2000} style={divStile}> More Albums </Button> : <div></div>}
          </div>
          <div className='containerRelease2010 contRel'>
            <h2 className='titleDecade'>Since 2010</h2>
            {<NestedGrid state={rotd2010} />}
            {twothousandTenTodayAlbum.length > 28 ? <Button id="bottoneAddMoreContent2010" className="moreAlbum" onMouseOver={changeColor} onMouseLeave={resetColor} onClick={() => AddMoreContent2010("2010", "dec")} value={2010} style={divStile}> More Albums </Button> : <div></div>}
          </div>
          <div className='tableNoCover-resText'>Table no cover album</div>
          <div id="containerTopPage" className="containerTopPageC">
            <div className="topPageInfoResC">
              <div data-text="" className="topPageInfo2C">
                <div className='allReleaseTopInfo'>
                  <div className='infoNoCoverDivRes'>
                    <Button className='showNoCoverButton nocoverButtonRes' onClick={() => noCoverAlbumSet("All")}>Show Table No Cover Album Found</Button>
                    <Picture className="iconArrowDown iconArrowRotate" src={require("../resources/downward-arrow.png").default} />
                  </div>
                </div>
                <div id='allReleaseTableNoCover' className='containerTableNoCover tableNoCoverUnique'>
                  <TableNoCover className='tableNoCover' stato={albumNonPresentiDirectoryCoverObj} />
                </div>
                <div className='50TopInfo'>
                  <div className='infoNoCoverDivRes'>
                    <Button className='showNoCoverButton nocoverButtonRes' onClick={() => noCoverAlbumSet("50")}>Show Table No Cover Album Found</Button>
                    <Picture className="iconArrowDown iconArrowRotate" src={require("../resources/downward-arrow.png").default} />
                  </div>
                </div>
                <div id='50TableNoCover' className='containerTableNoCover50 tableNoCoverUnique'>
                  <TableNoCover className='tableNoCover' stato={fiftySixtySeventyAlbumNoCover} />
                </div>
                <div className='80TopInfo'>
                  <div className='infoNoCoverDivRes'>
                    <Button className='showNoCoverButton nocoverButtonRes' onClick={() => noCoverAlbumSet("80")}>Show Table No Cover Album Found</Button>
                    <Picture className="iconArrowDown iconArrowRotate" src={require("../resources/downward-arrow.png").default} />
                  </div>
                </div>
                <div id='80TableNoCover' className='containerTableNoCover80 tableNoCoverUnique'>
                  <TableNoCover className='tableNoCover' stato={eightyNinetyAlbumNoCover} />
                </div>
                <div className='2000TopInfo'>
                  <div className='infoNoCoverDivRes'>
                    <Button className='showNoCoverButton nocoverButtonRes' onClick={() => noCoverAlbumSet("2000")}>Show Table No Cover Album Found</Button>
                    <Picture className="iconArrowDown iconArrowRotate" src={require("../resources/downward-arrow.png").default} />
                  </div>
                </div>
                <div id='2000TableNoCover' className='containerTableNoCover2000 tableNoCoverUnique'>
                  <TableNoCover className='tableNoCover' stato={twothousandTwothousandTenAlbumNoCover} />
                </div>
                <div className='2010TopInfo'>
                  <div className='infoNoCoverDivRes'>
                    <Button className='showNoCoverButton nocoverButtonRes' onClick={() => noCoverAlbumSet("2010")}>Show Table No Cover Album Found</Button>
                    <Picture className="iconArrowDown iconArrowRotate" src={require("../resources/downward-arrow.png").default} />
                  </div>
                </div>
                <div id='2010TableNoCover' className='containerTableNoCover2010 tableNoCoverUnique'>
                  <TableNoCover className='tableNoCover' stato={twothousandTenTodayAlbumNoCover} />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </section>
    )
  }
  else {
    return (
      <div>
        <Headroom>
          {/* <HorizontallyScrollingText text={"The day " + localDate.substring(5, 11).replace("-", "/") + " from the 50s have been released " + state.length + " Music Albums"} /> */}
          <div className='container-Hor'>
            <div className='container-logo-ori-navbar'>
              <Picture className="img-loro-ori" src={require("../resources/logoForBlackFinal.png").default} />
              <Picture className="img-loro-ori-text-dkt" src={require("../resources/ctvonly.png").default} />
            </div>
            <Marquee gradient={false} speed={50} direction="right">
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} className="scrolling-text" style={{ display: 'inline-block', marginRight: '10px' }}>
                  {" - The day " + localDate.substring(5, 11).replace("-", "/") + " from the 50s have been released " + state.length + " Music Albums "}
                </div>
              ))}
            </Marquee>
            <div className='container-logo-ori-navbar-right'>
              <MenuToggleButton />
            </div>
          </div>
        </Headroom>
        <div className="about-r">
          <WidthPage />
          <div>
            <BurgerMenuComponent
              AllReleaseOpen={AllReleaseOpen}
              closeAll50={closeAll50}
              closeAll80={closeAll80}
              closeAll2000={closeAll2000}
              closeAll2010={closeAll2010}
              removeMenuBar={removeMenuBar}
            />
          </div>
          <div className="about-pages-r">
            <div class="container-text-rotd">
              <div class="text-shadow">RECORDOFTHEDAY</div>
              <div>RECORDOFTHEDAY</div>
            </div>
            <div id="containerTopPage" className="containerTopPageC">
              <div className='container-top-content-1'>
                <div className='allReleaseDiv allReleaseDiv-AllYearsInfo'>All Releases</div>
                <div className='container-top-content-1-text'>All music albums released on this day from the 50's</div>
              </div>
              <div className='container-top-content-3'>
                <div className='container-top-content-3-text'>{localDate.substring(5, localDate.length).replace("-", "/")}</div>
              </div>
              <div className='container-top-content-2'>
                <div className='container-div-form-day'><FormDay /></div>
                <div className='container-top-content-2-text'>Change the day to see the releases on that day</div>
              </div>
            </div>
            {/* {<ModalInfoSite />} */}
            {<ModalModal2 />}
            {<ModalAlbumJs album={albumForInfo} />}
            <div className='containerRelease50 contRel'>
              <h2 className='titleDecade'>The '50s '60s and '70s</h2>
              {<NestedGrid state={rotd50} />}
              {fiftySixtySeventyAlbum.length > 28 ? <Button id="bottoneAddMoreContent50" className="moreAlbum" onMouseOver={changeColor} onMouseLeave={resetColor} onClick={() => AddMoreContent50("50", "dec")} value={50} style={divStile}> More Albums</Button> : <div></div>}
            </div>
            <div className='containerRelease80 contRel'>
              <h2 className='titleDecade'>The '80s and '90s</h2>
              {<NestedGrid state={rotd80} />}
              {eightyNinetyAlbum.length > 28 ? <Button id="bottoneAddMoreContent80" className="moreAlbum" onMouseOver={changeColor} onMouseLeave={resetColor} onClick={() => AddMoreContent80("80", "dec")} value={80} style={divStile}> More Albums </Button> : <div></div>}
            </div>
            <div className='containerRelease2000 contRel'>
              <h2 className='titleDecade'>From 2000 to 2010</h2>
              {<NestedGrid state={rotd2000} />}
              {twothousandTwothousandTenAlbum.length > 28 ? <Button id="bottoneAddMoreContent2000" className="moreAlbum" onMouseOver={changeColor} onMouseLeave={resetColor} onClick={() => AddMoreContent2000("2000", "dec")} value={2000} style={divStile}> More Albums </Button> : <div></div>}
            </div>
            <div className='containerRelease2010 contRel'>
              <h2 className='titleDecade'>Since 2010</h2>
              {<NestedGrid state={rotd2010} />}
              {twothousandTenTodayAlbum.length > 28 ? <Button id="bottoneAddMoreContent2010" className="moreAlbum" onMouseOver={changeColor} onMouseLeave={resetColor} onClick={() => AddMoreContent2010("2010", "dec")} value={2010} style={divStile}> More Albums </Button> : <div></div>}
            </div>
            <div className="containerTopPageinfoC">
              <div data-text="" className="topPageInfo2C">
                <div className='allReleaseTopInfo'>
                  <div className='allReleaseDiv'>Table no cover</div>
                  <div className='infoNoCoverDiv'>
                    <Button className='showNoCoverButton noCoverDesk' onMouseOver={changeColorNoCoverButton} onMouseLeave={resetColorNoCoverButton} onClick={() => noCoverAlbumSet("All")}>Show Table No Cover Album Found</Button>
                    <Picture className="iconArrowDown iconArrowRotate" src={require("../resources/downward-arrow.png").default} />
                  </div>
                </div>
                <div id='allReleaseTableNoCover' className='containerTableNoCover tableNoCoverUnique'>
                  <TableNoCover className='tableNoCover' stato={albumNonPresentiDirectoryCoverObj} />
                </div>
                <div className='50TopInfo'>
                  <div className='allReleaseDiv'>Table no cover</div>
                  <div className='infoNoCoverDiv'>
                    <Button className='showNoCoverButton noCoverDesk' onMouseOver={changeColorNoCoverButton} onMouseLeave={resetColorNoCoverButton} onClick={() => noCoverAlbumSet("50")}>Show Table No Cover Album Found</Button>
                    <Picture className="iconArrowDown iconArrowRotate" src={require("../resources/downward-arrow.png").default} />
                  </div>
                </div>
                <div id='50TableNoCover' className='containerTableNoCover50 tableNoCoverUnique'>
                  <TableNoCover className='tableNoCover' stato={fiftySixtySeventyAlbumNoCover} />
                </div>
                <div className='80TopInfo'>
                  <div className='allReleaseDiv'>Table no cover</div>
                  <div className='infoNoCoverDiv'>
                    <Button className='showNoCoverButton noCoverDesk' onMouseOver={changeColorNoCoverButton} onMouseLeave={resetColorNoCoverButton} onClick={() => noCoverAlbumSet("80")}>Show Table No Cover Album Found</Button>
                    <Picture className="iconArrowDown iconArrowRotate" src={require("../resources/downward-arrow.png").default} />
                  </div>
                </div>
                <div id='80TableNoCover' className='containerTableNoCover80 tableNoCoverUnique'>
                  <TableNoCover className='tableNoCover' stato={eightyNinetyAlbumNoCover} />
                </div>
                <div className='2000TopInfo'>
                  <div className='allReleaseDiv'>Table no cover</div>
                  <div className='infoNoCoverDiv'>
                    <Button className='showNoCoverButton noCoverDesk' onMouseOver={changeColorNoCoverButton} onMouseLeave={resetColorNoCoverButton} onClick={() => noCoverAlbumSet("2000")}>Show Table No Cover Album Found</Button>
                    <Picture className="iconArrowDown iconArrowRotate" src={require("../resources/downward-arrow.png").default} />
                  </div>
                </div>
                <div id='2000TableNoCover' className='containerTableNoCover2000 tableNoCoverUnique'>
                  <TableNoCover className='tableNoCover' stato={twothousandTwothousandTenAlbumNoCover} />
                </div>
                <div className='2010TopInfo'>
                  <div className='allReleaseDiv'>Table no cover</div>
                  <div className='infoNoCoverDiv'>
                    <Button className='showNoCoverButton noCoverDesk' onMouseOver={changeColorNoCoverButton} onMouseLeave={resetColorNoCoverButton} onClick={() => noCoverAlbumSet("2010")}>Show Table No Cover Album Found</Button>
                    <Picture className="iconArrowDown iconArrowRotate" src={require("../resources/downward-arrow.png").default} />
                  </div>
                </div>
                <div id='2010TableNoCover' className='containerTableNoCover2010 tableNoCoverUnique'>
                  <TableNoCover className='tableNoCover' stato={twothousandTenTodayAlbumNoCover} />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}