import React from "react";
import { getAlbum, secondFunction } from "../Grid/gridAlbum";
import { GridAlbum } from "../Grid/gridAlbum";
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';

var firstLoaded = false;


export function getAlbums() {
    getAlbum();
    $(".buttonGetAlbum").css("display", "none");
    $(".releasedOn").css("display", "block");
    $(".divContainerBottomGetAlbum").css("border-bottom", "1px solid black");
    $(".divReleasedOn").css("border-bottom", "none");
    $(".ctvHomeResInfo").css("display", "none");
    $(".containerRecordOfTheDay").css("height", "");
    $(".footerInfo").css("display", "none");
}

const Rotd = (props) => {
    getAlbums();
    var wi = parseInt($("#widthPaheAll").text());

    const { state } = props;
    if (wi < 900) {
        return (
            <div className="containerRecordOfTheDay">
                <div id="containerGridAlbumRes" className="containerGridAlbumRes">
                    <GridAlbum />
                </div>
            </div>
        )
    }
    else {
        return (
            <div id="containerRecordOfTheDay" className="containerRecordOfTheDay">
                <GridAlbum />
            </div>
        )
    }
}

export default Rotd;





