import React, { useState, useEffect } from 'react'
import _debounce from 'lodash.debounce'

const WidthPage = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = _debounce(() => setWidth(window.innerWidth), 100)

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  return <div id='widthPaheAll'>{width}</div>
}

export default WidthPage;