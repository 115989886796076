import React from "react";
import { useState, useEffect, useRef } from "react";
import bodymovin from "bodymovin";
import lottie from "lottie-web";
import $ from "jquery";
// import Delay from 'react-delay-render';
import { Picture } from "react-responsive-picture";


const AnimatedLogo = () => {
    // const container = useRef(null);

    // useEffect(() => {
    //     lottie.loadAnimation({
    //         container: container.current,
    //         renderer: 'svg',
    //         loop: true,
    //         autoplay: true,
    //         // animationData: require('./dotsGrid.json')
    //         animationData: require('./d3.json')
    //       })
    // }, []);


    return (
        <Picture className="containerAnimatedLogoIMG" src={require("../animation/logoAnimProva1_2.gif").default} />
    )
}

export default AnimatedLogo;