import React from "react";
import $ from 'jquery';
import { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { store } from "../redux/store";
import "../home/home.css";
import "../home/splitPageHome.css";
import ScrollMagic from 'scrollmagic';
import { Link } from "react-router-dom";
import DiscoAnim from '../animation/discoAnimation';
import { Picture } from 'react-responsive-picture';
import { Modal } from 'antd';
import "../home/homeResponsive.css";
import WidthPage from "./HookDimensions";
import { Row, Col } from 'antd';
import Footer from "../footer/footer";


var styleAboutTitle = {
  width: "15%",
  minWidth: "200px",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 !important",
  borderRight: "solid 2px black",
  flexDirection: "column",
  flexWrap: "nowrap",
  fontSize: "17px",
  alignItems: "stretch",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  top: "0px !important"
}

var wi = parseInt($("#widthPaheAll").text());

function triggerScrollHome() {
  if (wi > 900) {
    const controller = new ScrollMagic.Controller();
    new ScrollMagic.Scene({
      triggerElement: '.about-title',
      triggerHook: 0
    })
      .setPin('.about-title')
      .addTo(controller);
  }
}

const Home = () => {
  const menu = document.querySelector(".menu");
  const menuIcon = document.querySelector(".menuIcon");
  const closeIcon = document.querySelector(".closeMenuRes");

  $(document).ready(function () {
    if (wi > 900) {
      triggerScrollHome();
    }
  });



  function menuResponsive(action, item) {
    if (action == "open") {
      menu.classList.add("showMenu");
      closeIcon.style.display = "block";
      menuIcon.style.display = "none";
      $('html, body').css({
        overflow: 'hidden',
        height: '100%'
      });
    }
    if (action == "close") {
      menu.classList.remove("showMenu");
      closeIcon.style.display = "none";
      menuIcon.style.display = "block";
      $('html, body').css({
        overflow: '',
        height: ''
      });
    }
    if (action == "item") {
      menu.classList.remove("showMenu");
      closeIcon.style.display = "none";
      menuIcon.style.display = "block";
      $('html, body').css({
        overflow: '',
        height: ''
      });
      if (item == "AllArticles") {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        setArticles(allArticles);
        $(".infoTopPageArticles").html("All Articles:");

        //inserico bordi a
        $("#itemResAllArticles").css("border-bottom", "0.8px solid black");
        //rimuovo bordi items da
        $("#itemResAlbumOfTheMonth").css("border-bottom", "");
        $("#itemResAnniversary").css("border-bottom", "");
        $("#itemResSoundtrackCorner").css("border-bottom", "");
        $("#itemResVibeChecking").css("border-bottom", "");
        $("#itemResPlaylist").css("border-bottom", "");
        $("#itemResReviews").css("border-bottom", "");
        $("#itemResContactUs").css("border-bottom", "");
        $("#itemResMusicGraphics").css("border-bottom", "");
      }
      else if (item == "AlbumOfTheMonth") {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        var newArray = allArticles.filter(function (el) {
          return el.genre == "AlbumOfTheMonth"
        });
        setArticles(newArray);
        $(".infoTopPageArticles").html("Album Of The Month:");

        $("#itemResAllArticles").css("border-bottom", "");
        $("#itemResAlbumOfTheMonth").css("border-bottom", "0.8px solid black");
        $("#itemResAnniversary").css("border-bottom", "");
        $("#itemResSoundtrackCorner").css("border-bottom", "");
        $("#itemResVibeChecking").css("border-bottom", "");
        $("#itemResPlaylist").css("border-bottom", "");
        $("#itemResReviews").css("border-bottom", "");
        $("#itemResContactUs").css("border-bottom", "");
        $("#itemResMusicGraphics").css("border-bottom", "");
      }
      else if (item == "Anniversary") {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        var newArray = allArticles.filter(function (el) {
          return el.genre == "Anniversary"
        });
        setArticles(newArray);
        $(".infoTopPageArticles").html("Anniversary:");

        $("#itemResAllArticles").css("border-bottom", "");
        $("#itemResAlbumOfTheMonth").css("border-bottom", "");
        $("#itemResAnniversary").css("border-bottom", "0.8px solid black");
        $("#itemResSoundtrackCorner").css("border-bottom", "");
        $("#itemResVibeChecking").css("border-bottom", "");
        $("#itemResPlaylist").css("border-bottom", "");
        $("#itemResReviews").css("border-bottom", "");
        $("#itemResContactUs").css("border-bottom", "");
        $("#itemResMusicGraphics").css("border-bottom", "");
      }
      else if (item == "SoundtrackCorner") {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        var newArray = allArticles.filter(function (el) {
          return el.genre == "SoundtrackCorner"
        });
        setArticles(newArray);
        $(".infoTopPageArticles").html("Soundtrack Corner:");

        $("#itemResAllArticles").css("border-bottom", "");
        $("#itemResAlbumOfTheMonth").css("border-bottom", "");
        $("#itemResAnniversary").css("border-bottom", "");
        $("#itemResSoundtrackCorner").css("border-bottom", "0.8px solid black");
        $("#itemResVibeChecking").css("border-bottom", "");
        $("#itemResPlaylist").css("border-bottom", "");
        $("#itemResReviews").css("border-bottom", "");
        $("#itemResContactUs").css("border-bottom", "");
        $("#itemResMusicGraphics").css("border-bottom", "");
      }
      else if (item == "VibeChecking") {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        var newArray = allArticles.filter(function (el) {
          return el.genre == "VibeChecking"
        });
        setArticles(newArray);
        $(".infoTopPageArticles").html("Vibe Checking:");

        $("#itemResAllArticles").css("border-bottom", "");
        $("#itemResAlbumOfTheMonth").css("border-bottom", "");
        $("#itemResAnniversary").css("border-bottom", "");
        $("#itemResSoundtrackCorner").css("border-bottom", "");
        $("#itemResVibeChecking").css("border-bottom", "0.8px solid black");
        $("#itemResPlaylist").css("border-bottom", "");
        $("#itemResReviews").css("border-bottom", "");
        $("#itemResContactUs").css("border-bottom", "");
        $("#itemResMusicGraphics").css("border-bottom", "");
      }
      else if (item == "Playlist") {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        var newArray = allArticles.filter(function (el) {
          return el.genre == "Playlist"
        });
        setArticles(newArray);
        $(".infoTopPageArticles").html("Playlist:");

        $("#itemResAllArticles").css("border-bottom", "");
        $("#itemResAlbumOfTheMonth").css("border-bottom", "");
        $("#itemResAnniversary").css("border-bottom", "");
        $("#itemResSoundtrackCorner").css("border-bottom", "");
        $("#itemResVibeChecking").css("border-bottom", "");
        $("#itemResPlaylist").css("border-bottom", "0.8px solid black");
        $("#itemResReviews").css("border-bottom", "");
        $("#itemResContactUs").css("border-bottom", "");
        $("#itemResMusicGraphics").css("border-bottom", "");
      }
      else if (item == "Reviews") {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        var newArray = allArticles.filter(function (el) {
          return el.genre == "Reviews"
        });
        setArticles(newArray);
        $(".infoTopPageArticles").html("Reviews:");

        $("#itemResAllArticles").css("border-bottom", "");
        $("#itemResAlbumOfTheMonth").css("border-bottom", "");
        $("#itemResAnniversary").css("border-bottom", "");
        $("#itemResSoundtrackCorner").css("border-bottom", "");
        $("#itemResVibeChecking").css("border-bottom", "");
        $("#itemResPlaylist").css("border-bottom", "");
        $("#itemResReviews").css("border-bottom", "0.8px solid black");
        $("#itemResContactUs").css("border-bottom", "");
        $("#itemResMusicGraphics").css("border-bottom", "");
      }
      else if (item == "ContactUs") {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        var newArray = allArticles.filter(function (el) {
          return el.genre == "ContactUs"
        });
        setArticles(newArray);
        $(".infoTopPageArticles").html("Contact Us:");

        $("#itemResAllArticles").css("border-bottom", "");
        $("#itemResAlbumOfTheMonth").css("border-bottom", "");
        $("#itemResAnniversary").css("border-bottom", "");
        $("#itemResSoundtrackCorner").css("border-bottom", "");
        $("#itemResVibeChecking").css("border-bottom", "");
        $("#itemResPlaylist").css("border-bottom", "");
        $("#itemResReviews").css("border-bottom", "");
        $("#itemResContactUs").css("border-bottom", "0.8px solid black");
        $("#itemResMusicGraphics").css("border-bottom", "");
      }
    }
    else {

    }
  }

  const [error, setError] = useState(null);
  const [articles, setArticles] = useState([]);
  const [allArticles, setAllArticles] = useState([]);
  const [visible, setVisible] = useState(false);
  const [openBurgher, setOpenBurgher] = useState(false);

  const [selectetTitleArticle, setSelectetTitleArticle] = useState();
  const [selectetTextArticle, setSelectetTextArticle] = useState();
  const [selectetImgArticle, setSelectetImgArticle] = useState("logo1.png");

  var wi = parseInt($("#widthPaheAll").text());

  if (wi < 1430) {
    styleAboutTitle = {
      width: "15%",
      minWidth: "200px",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 !important",
      borderRight: "solid 2px black",
      flexDirection: "column",
      flexWrap: "nowrap",
      fontSize: "15px",
      alignItems: "stretch",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "flex-start",
      top: "0px !important"
    };
  }
  if (wi < 1320) {
    styleAboutTitle = {
      width: "15%",
      minWidth: "150px",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 !important",
      borderRight: "solid 2px black",
      flexDirection: "column",
      flexWrap: "nowrap",
      fontSize: "15px",
      alignItems: "stretch",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "flex-start",
      top: "0px !important"
    };
  }
  if (wi < 1090) {
    styleAboutTitle = {
      width: "15%",
      minWidth: "130px",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 !important",
      borderRight: "solid 2px black",
      flexDirection: "column",
      flexWrap: "nowrap",
      fontSize: "14px",
      alignItems: "stretch",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "flex-start",
      top: "0px !important"
    };
  }
  else {

  }
  if (wi < 900) {
    styleAboutTitle = {
      width: "0%",
      minWidth: "0px",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 !important",
      borderRight: "solid 2px black",
      flexDirection: "column",
      flexWrap: "nowrap",
      fontSize: "14px",
      alignItems: "stretch",
      display: "none",
      flexFlow: "column nowrap",
      justifyContent: "flex-start",
      top: "0px !important"
    };
  }
  else {
    $(".scrollmagic-pin-spacer").attr('style', 'width: 15% !important');
  }

  $(window).resize(function () {
    var wii = parseInt($("#widthPaheAll").text());
    if (wii > 900) {
      if (window.location.href.split('/')[window.location.href.split('/').length - 1] == 'Home' || window.location.href.split('/')[window.location.href.split('/').length - 1] == 'Home#') {
        triggerScrollHome();
      }
    }
  });

  useEffect(() => {
    $("#itemResAlbumOfTheMonth").css("border-bottom", "0.8px solid black");
    $("#albumOfTheMonth").css("border-bottom", "0.8px solid black");

    if (wi > 900) {
      const controller = new ScrollMagic.Controller();

      new ScrollMagic.Scene({
        triggerElement: '.about-title',
        triggerHook: 0
      })
        .setPin('.about-title')
        .addTo(controller);
    }


    fetch("https://testctv.herokuapp.com/articles")
      .then(res => res.json())
      .then(
        (result) => {
          setArticles(result.filter(function (el) {
            return el.genre == "AlbumOfTheMonth"
          }));
          setAllArticles(result);
        },
        (error) => {
          setError(error);
        }
      )
  }, [])

  function changeColorArticle(i) {
    if (wi > 900) {
      $("#" + i).css("background-color", "black");
      $("#" + i).css("color", "white");
    }
  }

  function resetColorArticle(i) {
    if (wi > 900) {
      $("#" + i).css("background-color", "white");
      $("#" + i).css("color", "black");
    }
  }

  function navigatorArticle(link) {
    if (link == "All") {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
      setArticles(allArticles);
      $(".infoTopPageArticles").html("All Articles:");

      $("#allArticles").css("border-bottom", "0.8px solid black");
      $("#albumOfTheMonth").css("border-bottom", "");
      $("#anniversary").css("border-bottom", "");
      $("#soundtrackCorner").css("border-bottom", "");
      $("#vibeChecking").css("border-bottom", "");
      $("#playlist").css("border-bottom", "");
      $("#reviews").css("border-bottom", "");
      $("#contactUs").css("border-bottom", "");
    }
    if (link == "month") {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
      var newArray = allArticles.filter(function (el) {
        return el.genre == "AlbumOfTheMonth"
      });
      setArticles(newArray);
      $(".infoTopPageArticles").html("Album of The Month:");

      $("#allArticles").css("border-bottom", "");
      $("#albumOfTheMonth").css("border-bottom", "0.8px solid black");
      $("#anniversary").css("border-bottom", "");
      $("#soundtrackCorner").css("border-bottom", "");
      $("#vibeChecking").css("border-bottom", "");
      $("#playlist").css("border-bottom", "");
      $("#reviews").css("border-bottom", "");
      $("#contactUs").css("border-bottom", "");
    }
    if (link == "anniversary") {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
      var newArray = allArticles.filter(function (el) {
        return el.genre == "Anniversary"
      });
      setArticles(newArray);
      $(".infoTopPageArticles").html("Anniversary:");

      $("#allArticles").css("border-bottom", "");
      $("#albumOfTheMonth").css("border-bottom", "");
      $("#anniversary").css("border-bottom", "0.8px solid black");
      $("#soundtrackCorner").css("border-bottom", "");
      $("#vibeChecking").css("border-bottom", "");
      $("#playlist").css("border-bottom", "");
      $("#reviews").css("border-bottom", "");
      $("#contactUs").css("border-bottom", "");
    }
    if (link == "SoundtrackCorner") {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
      var newArray = allArticles.filter(function (el) {
        return el.genre == "SoundtrackCorner"
      });
      setArticles(newArray);
      $(".infoTopPageArticles").html("Soundtrack Corner:");

      $("#allArticles").css("border-bottom", "");
      $("#albumOfTheMonth").css("border-bottom", "");
      $("#anniversary").css("border-bottom", "");
      $("#soundtrackCorner").css("border-bottom", "0.8px solid black");
      $("#vibeChecking").css("border-bottom", "");
      $("#playlist").css("border-bottom", "");
      $("#reviews").css("border-bottom", "");
      $("#contactUs").css("border-bottom", "");
    }
    if (link == "vibeChecking") {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
      var newArray = allArticles.filter(function (el) {
        return el.genre == "VibeChecking"
      });
      setArticles(newArray);
      $(".infoTopPageArticles").html("Vibe Checking:");

      $("#allArticles").css("border-bottom", "");
      $("#albumOfTheMonth").css("border-bottom", "");
      $("#anniversary").css("border-bottom", "");
      $("#soundtrackCorner").css("border-bottom", "");
      $("#vibeChecking").css("border-bottom", "0.8px solid black");
      $("#playlist").css("border-bottom", "");
      $("#reviews").css("border-bottom", "");
      $("#contactUs").css("border-bottom", "");
    }
    if (link == "playlist") {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
      var newArray = allArticles.filter(function (el) {
        return el.genre == "Playlist"
      });
      setArticles(newArray);
      $(".infoTopPageArticles").html("Playlist:");

      $("#allArticles").css("border-bottom", "");
      $("#albumOfTheMonth").css("border-bottom", "");
      $("#anniversary").css("border-bottom", "");
      $("#soundtrackCorner").css("border-bottom", "");
      $("#vibeChecking").css("border-bottom", "");
      $("#playlist").css("border-bottom", "0.8px solid black");
      $("#reviews").css("border-bottom", "");
      $("#contactUs").css("border-bottom", "");
    }
    if (link == "reviews") {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
      var newArray = allArticles.filter(function (el) {
        return el.genre == "Reviews"
      });
      setArticles(newArray);
      $(".infoTopPageArticles").html("Reviews:");

      $("#allArticles").css("border-bottom", "");
      $("#albumOfTheMonth").css("border-bottom", "");
      $("#anniversary").css("border-bottom", "");
      $("#soundtrackCorner").css("border-bottom", "");
      $("#vibeChecking").css("border-bottom", "");
      $("#playlist").css("border-bottom", "");
      $("#reviews").css("border-bottom", "0.8px solid black");
      $("#contactUs").css("border-bottom", "");
    }
    if (link == "contactUs") {
      $('html, body').animate({ scrollTop: 0 }, 'fast');
      $(".infoTopPageArticles").html("Contact Us:");

      $("#allArticles").css("border-bottom", "");
      $("#albumOfTheMonth").css("border-bottom", "");
      $("#anniversary").css("border-bottom", "");
      $("#soundtrackCorner").css("border-bottom", "");
      $("#vibeChecking").css("border-bottom", "");
      $("#playlist").css("border-bottom", "");
      $("#reviews").css("border-bottom", "");
      $("#contactUs").css("border-bottom", "0.8px solid black");
    }
  }

  function setModalArticle(article) {
    setVisible(true);
    setSelectetTitleArticle(article.title);
    setSelectetTextArticle(article.textarticle);
    setSelectetImgArticle(article.img);
  }

  const ModalArticle = () => {
    return (
      <>
        <Modal
          title={selectetTitleArticle}
          centered
          cancelButtonProps={{ 'id': 'cancelButtonModal' }}
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
          <Picture className="imgArticle imgArticleModal" src={require("../resources/imgArticles/" + selectetImgArticle).default} />
          <div className="containerTextArticleModal" dangerouslySetInnerHTML={{ __html: selectetTextArticle }}></div>
        </Modal>
      </>
    );
  };

  const mStyleResponsive = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxHeight: "110px",
    paddingTop: "6px",
    paddingBottom: "6px",
    justifyContent: "center",
    zIndex: "50"
  };
  const mStyleResponsive1 = {
    display: "flex",
    alignItems: "flexStart",
    justifyContent: "center",
    flexDirection: "column",
    maxHeight: "110px",
    paddingTop: "6px",
    paddingBottom: "6px"
  };

  function setMenuFromEq() {
    $(".divItemsRotdNav").addClass("divItemsNavbarSet");
    $(".divItemsHomeNav").removeClass("divItemsNavbarSet");
  }

  const textSlideInfoRotd = store.getState().length + " Albums released Today.  check them out";

  const RenderArticles = ({ articles }) => (
    articles.map((article, i) => (
      <div>
        {i % 2 === 1 ? (
          <div className={article.id} key={i} id="containerSingleArticle" onClick={() => setModalArticle(article)} onMouseOver={() => changeColorArticle(article.id)} onMouseLeave={() => resetColorArticle(article.id)}>
            <div className="containerTextArticle">
              <div id={article.id} className="titleSingleArticle">
                <div className="titleAr">
                  {article.title.length > 30 ? (
                    <div className="setArticleFontTitle">{article.title}</div>
                  ) : (
                    article.title
                  )}
                </div>
              </div>
              <div className="img-TextArticle">
                <div className="textSingleArticle textSingleArticleEven">
                  <div className="txtArticle" dangerouslySetInnerHTML={{ __html: article.textarticle }}></div>
                  <div className="infoArticleAuthor infoArticleAuthorEven">
                  </div>
                </div>
                <Picture className="imgArticle" src={require("../resources/imgArticles/" + article.img).default} />
              </div>
            </div>
          </div>
        ) : (
          <div className={article.id} key={i} id="containerSingleArticle" onClick={() => setModalArticle(article)} onMouseOver={() => changeColorArticle(article.id)} onMouseLeave={() => resetColorArticle(article.id)}>
            <div className="containerTextArticle">
              <div id={article.id} className="titleSingleArticle oddTitleArticle">
                <div className="titleAr">
                  {article.title.length > 32 ? (
                    <div className="setArticleFontTitle">{article.title}</div>
                  ) : (
                    article.title
                  )}
                </div>
              </div>
              <div className="img-TextArticle oddArticle">
                <Picture className="imgArticle" src={require("../resources/imgArticles/" + article.img).default} />
                <div className="textSingleArticle textSingleArticleOdd">
                  <div className="txtArticle" dangerouslySetInnerHTML={{ __html: article.textarticle }}></div>
                  <div className="infoArticleAuthor infoArticleAuthorOdd">
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    ))
  );

  if (wi > 900) {
    return (
      <section className="about">
        <WidthPage />
        <div className="about-title" id="about-title" style={styleAboutTitle}>
          <div className="containerNavIcons">
            <div className="magicNavItem itemNavRecordOfTheDay">
              <Link onClick={() => setMenuFromEq()} to="/InfoRecordOfTheDay" className="Nav__brand aRecordOfTheDay">
                <div className="containerDiscoAnimLateralMenu">
                  <DiscoAnim />
                </div>
              </Link>
            </div>
          </div>
          <h2 className="topMenuTitle">Articles</h2>
          <a style={{ display: "none" }} id="allArticles" onClick={() => navigatorArticle("All")} className="menu-item itemHome">All Articles</a>
          <a id="albumOfTheMonth" onClick={() => navigatorArticle("month")} className="menu-item itemHome"><div>Album of the</div><div>month</div></a>
          <a id="anniversary" onClick={() => navigatorArticle("anniversary")} className="menu-item itemHome">Anniversary</a>
          <a id="soundtrackCorner" onClick={() => navigatorArticle("SoundtrackCorner")} className="menu-item itemHome">Soundtrack corner</a>
          <a id="vibeChecking" onClick={() => navigatorArticle("vibeChecking")} className="menu-item itemHome">Vibe Checking</a>
          <a id="playlist" onClick={() => navigatorArticle("playlist")} className="menu-item itemHome">Playlist</a>
          <h2 className="topMenuTitle topMenuTitleVibes">Vibez</h2>
          <Link to="/AboutUs" className="linkAboutUs">
            <a id="contactUs" onClick={() => navigatorArticle("contactUs")} className="menu-item itemHome">About Us</a>
          </Link>
        </div>
        <div className="about-pages">
          <div className="infoTopPageArticles">
            Album Of The Month:
          </div>
          <ModalArticle />
          <RenderArticles articles={articles} />
          <Footer />
        </div>
      </section>
    )
  }
  else {
    return (
      <div>
        <section className="about">
          <WidthPage />
          <div className="about-title" style={styleAboutTitle}>
          </div>
          <ul className="menu">
            <div className="containerMenuResponsive">
              <div className="containerIconCloseAndArticleText">
                <div className="itemMenuResponsive">Articles</div>
                <div className="closeMenuRes">
                  <Picture onClick={() => menuResponsive("close")} className="closeMenuResponsive" src={require("../resources/icons8-close-30white.png").default} />
                </div>
              </div>
              <li style={{ display: "none" }}><a id="itemResAllArticles" onClick={() => menuResponsive("item", "AllArticles")} className="menuItem" href="#">All Articles</a></li>
              <li><a id="itemResAlbumOfTheMonth" onClick={() => menuResponsive("item", "AlbumOfTheMonth")} className="menuItem" href="#">Album of the Month</a></li>
              <li><a id="itemResAnniversary" onClick={() => menuResponsive("item", "Anniversary")} className="menuItem" href="#">Anniversary</a></li>
              <li><a id="itemResSoundtrackCorner" onClick={() => menuResponsive("item", "SoundtrackCorner")} className="menuItem" href="#">Soundtrack Corner</a></li>
              <li><a id="itemResVibeChecking" onClick={() => menuResponsive("item", "VibeChecking")} className="menuItem" href="#">Vibe Checking</a></li>
              <li><a id="itemResPlaylist" onClick={() => menuResponsive("item", "Playlist")} className="menuItem" href="#">Playlist</a></li>
              <div className="itemMenuResponsiveVibez">Vibez</div>
              <Link to="/AboutUs" className="linkAboutUs">
                <li><a id="itemResContactUs" onClick={() => menuResponsive("item", "ContactUs")} className="menuItem" href="">About Us</a></li>
              </Link>
            </div>
          </ul>
          <div className="about-pages">
            <div className="ctvhomecontainer">
              <Picture className="ctvHome" src={require("../resources/checkarticlesBlack.png").default} />
            </div>
            <Row>
              <Col span={20} style={mStyleResponsive1}>
                <div className="infoTopPageArticles">
                  Album of the Month:
                </div>
              </Col>
              <Col span={4} style={mStyleResponsive}>
                <button className="hamburger">
                  <i onClick={() => menuResponsive("open")} className="menuIcon material-icons">
                    <Picture className="iconBurgherOpen" src={require("../resources/icons8-menu-50.png").default} />
                  </i>
                </button>
              </Col>
            </Row>
            <ModalArticle />
            <RenderArticles articles={articles} />
          </div>
        </section>
        <div class="hwrap"><div class="hmove">
          <div class="hslide">
            <div>{textSlideInfoRotd}</div>
          </div>
          <div class="hslide">
            <div>{textSlideInfoRotd}</div>
          </div>
          <div class="hslide">
            <div>{textSlideInfoRotd}</div>
          </div>
        </div></div>
      </div>
    )
  }
}

export default Home;