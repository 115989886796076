export const incrementCountAction = (count) => {
    return {
      type: "INCREMENT",
      payload: count
    }
}

export const setAlbumStoreAction = (album) => {
    return {
      type: "ASSIGN",
      payload: album
    }
}

export const setMenuOpen = (keys) => {
  return {
    type: "MENU",
    payload: keys
  }
}

export const setJsonState = (json) => {
  return {
    type: "JSONSTATE",
    payload: json
  }
}

export const setArticles = (Articles) => {
  return {
    type: "ARTICLES",
    payload: Articles
  }
}