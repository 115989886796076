import React from 'react';
import { Link } from 'react-router-dom';
import EqAnim from '../animation/eqAnimation';
import { useMenu } from './MenuContext';
import { Picture } from 'react-responsive-picture';

const BurgerMenuComponent = ({ AllReleaseOpen, closeAll50, closeAll80, closeAll2000, closeAll2010, removeMenuBar }) => {
    const { isMenuOpen, toggleMenu } = useMenu();

    return (
        <div className={`div-b-menu ${isMenuOpen ? 'open' : ''}`}>
            <h2 className="topMenuTitle">Vibez <Picture onClick={toggleMenu} className="img-hamburgher-close" src={require("../resources/icons8-close-30white.png").default} /></h2>
            <a id="allArticles" onClick={AllReleaseOpen} className="menu-item itemHome">All Releases <EqAnim /></a>
            <a id="articles50" onClick={closeAll50} className="menu-item itemHome">The '50s '60s and '70s <EqAnim /></a>
            <a id="articles80" onClick={closeAll80} className="menu-item itemHome">The '80s and '90s <EqAnim /></a>
            <a id="articles2000" onClick={closeAll2000} className="menu-item itemHome">From 2000 to 2010 <EqAnim /></a>
            <a id="articles2010" onClick={closeAll2010} className="menu-item itemHome">Since 2010 <EqAnim /></a>
            <Link to="/AboutUs" className="linkAboutUs">
                <a onClick={removeMenuBar} id="contactUs" className="menu-item itemHome">About Us</a>
            </Link>
        </div>
    );
};

export default BurgerMenuComponent;
