import React from 'react';
import $ from 'jquery';
import '../Grid/HorizontalScrollText.css'; // Import your CSS file
import MenuToggleButton from './MenuToggleButton';
import { Picture } from 'react-responsive-picture';


const HorizontallyScrollingText = ({ text }) => {
    if ($(window).width() > 900) {
        return (
            <div className='container-Hor'>
                <div className='container-logo-ori-navbar'>
                    <Picture className="img-loro-ori" src={require("../resources/logoForBlackFinal.png").default} />
                    <Picture className="img-loro-ori-text-dkt" src={require("../resources/ctvonly.png").default} />
                </div>
                
                <div className="horizontally-scrolling-text-container">
                    <div className="horizontally-scrolling-text">
                        {text}
                    </div>
                </div>

                <div className='container-logo-ori-navbar-right'>
                    <MenuToggleButton />
                </div>                
            </div>
        );
    }
    else {
        return (
            <div className='container-Hor container-Hor-res'>
                <div className="horizontally-scrolling-text-container-res">
                    <div className="horizontally-scrolling-text horizontally-scrolling-text-res">
                        {text}
                    </div>
                </div>
            </div>
        );
    }
};

export default HorizontallyScrollingText;
