import React from "react";
import { useState, useEffect, useRef } from "react";
import bodymovin from "bodymovin";
import lottie from "lottie-web";
import $ from "jquery";


const DiscoAnimLoader = () => {
    const container = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('./moreAlbumLoader.json')
          })
    }, []);


    return (
        <div className="containerAnimDiscoLoader" ref={container}>

        </div>
    )
}

export default DiscoAnimLoader;