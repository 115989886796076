import * as React from 'react';
import "./modal.css";
import DiscoAnimLoader from "../animation/discoAnimLoader";


export function ModalModal2() {
    return (
        <div id="myModal" className="modal">
        <div className="Mmodal-content">
          <DiscoAnimLoader/>
        </div>
      </div>
    )
}
