import React from "react";
import { useState, useEffect, useRef } from "react";
import bodymovin from "bodymovin";
import lottie from "lottie-web";
import $ from "jquery";
// import Delay from 'react-delay-render';


const VinylLoadAppModal = () => {
    const container = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            // animationData: require('./dotsGrid.json')
            animationData: require('./vinylLoadApp.json')
          })
    }, []);


    return (
        <div className="containerAnimVinylLoadAppModal" ref={container}>

        </div>
    )
}

export default VinylLoadAppModal;