import * as React from 'react';
import "./modalAlbum.css";
import DiscoAnimLoader from "../animation/discoAnimLoader";
import $ from 'jquery';
import { useState } from 'react';
import VinylLoadAppModal from '../animation/vinylLoadAppModal';
import SpotifyAlbumEmbed from './spotifyWidget';

function closeModal() {
    $(".modalA").css("display", "none");
    $('body').removeClass('stop-scrolling');
    $('.i-frame-spotify-ctv').attr('src', '');

}

const urlTestCtv = "https://testctv.herokuapp.com";
const urlTestCtvLocalHostSpotify = "http://localhost:3001/get-album-spotify";
const urlTestCtvHostSpotify = "https://testctv.herokuapp.com/get-album-spotify";

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

async function getInfoAlbumSpotify(artist, nameAlbum, yearAlbum) {
    try {
        const response = await fetch(urlTestCtvHostSpotify, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "artist": artist, "nameAlbum": nameAlbum, "yearAlbum": yearAlbum })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json(); // Aspetta che la promessa si risolva
        // console.log("res data album from spotify", data);
        return data; // Restituisce i dati deserializzati
    } catch (error) {
        console.error("Errore durante la richiesta all'API:", error);
        return null; // Gestisce l'errore restituendo null o un valore appropriato
    }
}

// Assicurati di chiamare getInfoAlbumSpotify in un contesto asincrono
// var idAlbumSpotify = await getInfoAlbumSpotify(props[0].info.artist, props[0].info.title);



export function ModalAlbumJs(props, newDateForModal) {
    // console.log("newDateForModal", newDateForModal);
    // const [album, setAlbum] = useState([{"title" : "as", "number" : "0"}]); 
    // const [idImgAlbum, setIdImgAlbum] = useState(""); 
    // const [albumSpotify, setAlbumSpotify] = useState(""); 
    // const [albumSpotify, setAlbumSpotify] = useState("");


    var albumIdSpotify = "";

    var newLocalDate = "";
    if (newDateForModal.length > 5) {
        newLocalDate = "2023-" + newDateForModal.substring(newDateForModal.length - 5);
    }
    else {
        newLocalDate = "2023-" + newDateForModal;
    }
    //console.log("newDateForModal", newLocalDate);


    function first() {
        return new Promise(function (resolve, reject) {
            setTimeout((function () {
                $(".ContainerVinylLoaderModal").css("display", "none");
                resolve("Stuff worked!");
            }), 1000);
        });
    }
    function second() {
        $(".containerInfoAlbumModal").css("display", "flex");
    }


    const dates = new Date()
    const results = dates.toLocaleDateString(undefined, { // you can use undefined as first argument
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    });
    // // console.log("result", results);
    var newStr = results.split("/");
    var localDates = newStr[2] + "-" + newStr[1] + "-" + newStr[0];
    var localDate = localDates.substring(0, 10);

    var title = $(".titleAlbumModal");
    var artist = $(".artistAlbumModal");
    var releaseDate = $(".releaseDataAlbumModal");

    var newDateRelease = formatDate(new Date()).substring(5, 11);
    //console.log("newLocalDate", newLocalDate);

    if (props.length > 0) {
        // console.log("òàòàò",props[0].info);
        title.text(props[0].info.title);
        artist.text(props[0].info.artist);
        var d = props[0].info.dataInfo;
        releaseDate.text(d);
        $('.imgAlbumForInfoModal').attr('src', urlTestCtv + "/images/" + props[0].info.idImg + ".jpeg&" + newLocalDate);
        $(".containerTrackModal").empty();


        getInfoAlbumSpotify(props[0].info.artist, props[0].info.title, d.substring(0, 4))
            .then(idAlbumSpotify => {
                if (idAlbumSpotify == "Album non trovato dopo nessuna chiamata") {
                    $('.i-frame-spotify-ctv').hide();
                }
                else {
                    var nuovoSrc = "https://open.spotify.com/embed/album/" + idAlbumSpotify;

                    // Seleziona l'iframe tramite la classe e aggiorna l'attributo src
                    $('.i-frame-spotify-ctv').show();
                    $('.i-frame-spotify-ctv').attr('src', nuovoSrc);
                }
            })
            .catch(error => {
                console.error("Errore durante la richiesta:", error);
            });


        first().then(second);

        for (var i = 1; i < props.length; i++) {
            $(".containerTrackModal").append("<div>" + i + " - " + props[i].title + "</div>");
        }
    }
    else {
        title.text("");
    }

    // console.log("props MOdal length= ", props.length);
    // console.log("props MOdal= ", props);

    return (
        <div id="myModalA" className="modalA">
            <div className="modal-contentA">
                <div className="ContainerVinylLoaderModal">
                    <VinylLoadAppModal />
                </div>
                <div className='containerInfoAlbumModal'>
                    <div className='containerButtonModalAlbum'>
                        <button className="closeA buttonCloseModalA" onClick={() => closeModal()} >&times;</button>
                    </div>
                    {/* <div className='infoAlbumModal'>Info Album</div> */}
                    <div className='containerImgAlbumModal'>
                        <img className="responsive-img imgAlbumForInfoModal" />
                    </div>
                    <div className='containerAllInfoModalAlbum'>
                        <div className='containerTitleBlockModal'>
                            <div className='textTitleAlbumModal'>Title</div>
                            <div className='titleAlbumModal'></div>
                        </div>
                        <div className='containerAlbumBlockModal'>
                            <div className='textTitleAlbumModal'>Artist</div>
                            <div className='artistAlbumModal'></div>
                        </div>
                        <div className='containerAlbumBlockModal marginReleaseDate'>
                            <div className='textTitleAlbumModal'>Release date</div>
                            <div className='releaseDataAlbumModal'></div>
                        </div>
                    </div>
                    <div className='containerSpotify'>
                        {/* <SpotifyAlbumEmbed albumId={albumIdSpotify}/> */}
                        <iframe
                            className='i-frame-spotify-ctv'
                            src=''
                            width="300" // Puoi adattare queste dimensioni alle tue esigenze
                            height="380"
                            frameBorder="0"
                            allowtransparency="true"
                            allow="encrypted-media"
                        ></iframe>
                    </div>
                    <div className='containerAllElementsTracks'>
                        <div className='textTracksAlbumModal'>Tracks</div>
                        <div className='containerTrackModal'>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
