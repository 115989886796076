import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import "../home/gridArticle.css";
import { useLayoutEffect, useState } from 'react';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function uniqueArray(data, key) {
    if (data && key) {
        return [
            ...new Map(
                data.map(x => [key(x), x])
            ).values()
        ]
    }
    else {
        return <div></div>
    }
}

var arrayGridArticles = [];
var arrayAlbum = {};

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const RenderArticles = (articoli) => {
    const [width, height] = useWindowSize();
    // var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    // // // console.log("width gridArticles", width);

    useEffect(() => {
        // // // console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
        if (width <= 600) {
            alert("ds");
        }
    });



    // // console.log("renderArticles", articoli);
    for (var i = 0; i < articoli.articles.length; i++) {

        var divArticles = <Box key={i} gridColumn="span 8">
            <Item key={articoli.articles[i].id}>
                <div className='containerArt' key={articoli.articles[i].id}>
                    <div className='titleArticles'>
                        {articoli.articles[i].title}
                    </div>
                    <div className='textArticles'>
                        {articoli.articles[i].Textarticle}
                    </div>
                    <div className='imgArticles'>
                        <img className="imgArticle" src={require("../resources/imgArticles/" + articoli.articles[i].img).default}></img>
                    </div>
                </div>
            </Item>
        </Box>
        arrayGridArticles.push(divArticles);

        arrayAlbum = {
            divElements: uniqueArray(arrayGridArticles, it => it.key)
        }
    }



    return (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            {arrayAlbum.divElements}
        </Box>
    );
}

const GridArticle = (props) => {
    var articoli = props.articles;
    // // console.log("propsArticles", articoli);

    return (
        <Box sx={{ width: 1 }}>
            <RenderArticles articles={articoli} />
            {/* <Box gridColumn="span 8">
                    <Item>xs=8</Item>
                </Box>
                <Box gridColumn="span 4">
                    <Item>xs=4</Item>
                </Box>
                <Box gridColumn="span 4">
                    <Item>xs=4</Item>
                </Box>
                <Box gridColumn="span 8">
                    <Item>xs=8</Item>
                </Box> */}
        </Box>
    );
}

export default GridArticle;
