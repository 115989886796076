import 'antd/dist/antd.css';
import './App.css';
import { setAlbumStoreAction, setMenuOpen } from "../src/redux/actions";
import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route, Redirect, Switch
} from 'react-router-dom';
import Home from './home/home';
import { store } from './redux/store';
import { MenuBar } from './home/menuBar';
import $ from 'jquery';
import DocumentMeta from 'react-document-meta';
import { Helmet } from "react-helmet";
import Footer from './footer/footer';
import { getIdAlbumPresenti } from '../src/Grid/gridAlbum.js';
import Rotd from './RecordOfTheDay/Rotd';
import React from 'react';
import { Prompt } from 'react-router-dom';
import { Observable } from 'object-observer';
import AboutUs from './home/aboutUs';
import AnimatedLogo from './animation/animatedLogo';
import { ModalInfoSite } from './Modal/modalInfoSite.js';

var totalAlbum = [];
var popolateArrayOk = false;
var popolateArrayCheckObservable = [];
var popolateArrayChangeDay = [];

const meta = {
  title: 'CheckTheVibez',
  description: '',
  canonical: 'http://example.com/path/to/page',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags'
    }
  }
}

window.isMenuOpen = false;

export function toggleMenu() {
  window.isMenuOpen = !window.isMenuOpen;
  const event = new CustomEvent('menuToggle', { detail: window.isMenuOpen });
  window.dispatchEvent(event);
}

function setNavigationUnderline() {
  const path = window.location.pathname.split('/').pop();
  if (path === 'InfoRecordOfTheDay') {
    $(".selectedRecordOfTheDay").css("display", "block");
    $(".selectedHome").css("display", "none");
    $(".selectedContact").css("display", "none");
    $(".selectedMusicGraphics").css("display", "none");
    $(".divItemsRotdNav").addClass("divItemsNavbarSet");
    $(".divItemsHomeNav").removeClass("divItemsNavbarSet");
  } else if (path === 'Home') {
    $(".selectedRecordOfTheDay").css("display", "none");
    $(".selectedHome").css("display", "block");
    $(".selectedContact").css("display", "none");
    $(".selectedMusicGraphics").css("display", "none");
    $(".divItemsHomeNav").addClass("divItemsNavbarSet");
    $(".divItemsRotdNav").removeClass("divItemsNavbarSet");
    $('body').removeClass('stop-scrolling');
  }
}

export function firstFunctionApp(done) {
  var res = false;
  if (done === "done") {
    popolateArrayOk = true;
    res = true;
    observableAlbum.push(1);
    return res;
  } else {
    res = false;
  }
  return res;
}

const observableAlbum = Observable.from(popolateArrayCheckObservable);

const App = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedFunction, setIsLoadedFunction] = useState(firstFunctionApp());
  const [items, setItems] = useState([]);
  const testAnimLogo = false;
  const [thisState, setThisState] = useState(store.getState());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  observableAlbum.observe(changes => {
    setTimeout(() => { setIsLoadedFunction(true); }, 3000);
  });

  const moment = require('moment');
  const currentDate = moment().format('YYYY-MM-DD');
  const localDate = currentDate;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth !== windowWidth) {
        setWindowWidth(window.innerWidth);
        setThisState({ ...thisState });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth, thisState]);


  window.mobileCheck = function () {
    let check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  window.mobileAndTabletCheck = function () {
    let check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };


  $(document).ready(function () {
    const path = window.location.pathname.split('/').pop();
    if (path === 'InfoRecordOfTheDay') {
      $(".selectedRecordOfTheDay").css("display", "block");
      $(".selectedHome").css("display", "none");
      $(".selectedContact").css("display", "none");
      $(".selectedMusicGraphics").css("display", "none");
      $(".divItemsRotdNav").addClass("divItemsNavbarSet");
      $(".divItemsHomeNav").removeClass("divItemsNavbarSet");
      $(".menuBarApp").css("display", "flex");
    } else if (path === 'Home') {
      $(".selectedRecordOfTheDay").css("display", "none");
      $(".selectedHome").css("display", "block");
      $(".selectedContact").css("display", "none");
      $(".selectedMusicGraphics").css("display", "none");
      $(".divItemsHomeNav").addClass("divItemsNavbarSet");
      $(".divItemsRotdNav").removeClass("divItemsNavbarSet");
      $(".menuBarApp").css("display", "flex");
    } else if (path === 'AboutUs') {
      $(".menuBarApp").css("display", "none");
    }
  });

  useEffect(() => {
    fetch("https://testctv.herokuapp.com/RecordOfTheDay", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "dataToday": localDate })
    })
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);
          totalAlbum = result;
          store.dispatch(setAlbumStoreAction(result));
          getIdAlbumPresenti();
          setTimeout(() => {
            setIsLoaded(true);
          }, 1000);
        },
        (error) => {
          setTimeout(() => {
            setIsLoaded(true);
          }, 1500);
          setError(error);
        }
      );
  }, [localDate]);

  function openMenu() {
    toggleMenu();
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoadedFunction) {
    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>CheckTheVibez</title>
        </Helmet>
        <Router>
          <main id="mainLoaderApp" className="main-content">
            <div className="containerLoaderApp">
              <AnimatedLogo />
            </div>
          </main>
        </Router>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>CheckTheVibez</title>
        </Helmet>
        <div>
          <DocumentMeta {...meta} />
          <Router>
            <Prompt message={(location, action) => {
              if (action === 'POP') {
                setNavigationUnderline();
              }
            }} />
            <MenuBar onclick={openMenu} />
            <main className="main-content">
              <Switch>
                <Route path="/" exact>
                  <Redirect to="/InfoRecordOfTheDay" />
                </Route>
                <Route path="/Home" exact>
                  <Home />
                </Route>
                <Route path="/InfoRecordOfTheDay" exact>
                  <Rotd state={thisState} />
                  <ModalInfoSite />
                </Route>
                <Route path="/AboutUs" exact>
                  <AboutUs />
                </Route>
              </Switch>
            </main>
          </Router>
        </div>
      </div>
    );
  }
}


export default App;
