import { reduce } from "async";
import { createStore } from "redux";
import { reducer } from "./reducer";
import { incrementCountAction } from "./actions";

//initialState is optional.
//For this demo, I am using a counter, but usually state is an object
const initialState = {
    number: 0,
    album: [],
    keys: 0,
    articles: []
};
export const store = createStore(reducer, initialState);

//reducer(initialState, incrementCountAction);
//reducer(initialState, incrementCountAction);

store.subscribe(()=>{
    // // // console.log("State has changed" + store.getState())
})