import * as React from 'react';
import "./modalInfoSite.css";
import DiscoAnimLoader from "../animation/discoAnimLoader";
import $ from 'jquery';


var checkFirstOpenApp = false;

function closeModal() {
  $(".myModal-infosite").css("display", "none");
  $('body').removeClass('stop-scrolling');
  checkFirstOpenApp = true;
}


export function ModalInfoSite() {

  if(checkFirstOpenApp == false) {
    $('body').addClass('stop-scrolling');
  }


  return (
    <div id="myModal-infosite" className="myModal-infosite">
      <div className="Mmodal-content-infosite">
        <h3>CheckTheVibez</h3>
        <p>A website created for music lovers, non-profit, with the primary goal of providing music information.</p>

        <h3>Record Of the Day</h3>
        <p>Through the "Record Of the Day" page, it's possible to view all the music albums released on a specific day (by default, the current day) in history, starting from the 1950s up to the albums released in the previous year. CheckTheVibez aims to celebrate and discover musical albums, their tracks, and covers. You can change the selected day using the "Select Your Day" option.</p>

        <h3>Sources of Information</h3>
        <p>The information has been gathered from the open-source MusicBrainz database and the covers from <a className='linkInfoCoverArtArchiveModal' href="https://www.openartsarchive.org/">https://www.openartsarchive.org/</a>. For the creation of the site and the server/client infrastructure, the data in the MusicBrainz database were processed and integrated into another database by the developers.</p>

        <p>Given the vast amount of albums and considering that, in many cases, the first release date might not correspond to the official one, errors might occur on the site. However, we expect this to happen only in a minimal percentage of cases.</p>
        <p>Come and check the vibez.</p>
        <div className='div-buttonModalInfoSiteClosingModal'>
          <button className='buttonModalInfoSiteClosingModal' onClick={() => closeModal()}>Enter</button>
        </div>
      </div>
    </div>
  )
}
