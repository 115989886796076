import { Space, Table, Tag } from 'antd';
import React from 'react';
import 'antd/dist/antd.css';
const { Column, ColumnGroup } = Table;

const TableNoCover = (props) => {
    props.stato.forEach(function(n) {
        n.Date = n.Date.substring(0, 4);
    });
    return (
        <Table width={'100%'} dataSource={props.stato}>
            <Column className='columnNoCoverTable' title="Artist" dataIndex="Artist" key="Artist" />
            <Column title="Title" dataIndex="Title" key="Title" />
            <Column title="Date" dataIndex="Date" key="Date" />
        </Table>
    );
};

export default TableNoCover;