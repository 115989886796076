import React from "react";
import $ from 'jquery';
import { useState, useLayoutEffect } from "react";
import { Picture } from "react-responsive-picture";
import { Col, Row } from "antd";
import "./footerResponsive.css";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const Footer = (props) => {
    const [width, height] = useWindowSize();

    var wi = parseInt($("#widthPaheAll").text());


    if (width < 900) {
        return (
            <div className='footerResponsive'>
                <Row>
                    <Col className='itemsResColFooter' span={8}>
                        <a href="https://instagram.com/ctvbz?igshid=YmMyMTA2M2Y=">
                            <Picture className="fooIgImgRes fooImgRes" src={require("../resources/ig.png").default} />
                        </a>
                    </Col>
                    <Col className='itemsResColFooter' span={8}>
                        <Picture className="fooImgLogoRes" src={require("../resources/logoBlack2.png").default} />
                    </Col>
                    <Col className='itemsResColFooter' span={8}>
                        <a href="https://open.spotify.com/user/0116ljq40q8901gk5ckcjv5hf?si=VsfLI1vEROqimMWoaKKZTA">
                            <Picture className="fooSpotifyImgRes fooImgRes" src={require("../resources/spotify-256.png").default} />
                        </a>
                    </Col>
                </Row>
            </div>
        )
    }
    else {
        if (window.location.href.split('/')[window.location.href.split('/').length - 1] == 'InfoRecordOfTheDay' || window.location.href.split('/')[window.location.href.split('/').length - 1] == 'InfoRecordOfTheDay#') {
            return (
                <footer className="footerRotd">
                    <div className="fooContRotd">
                        <Row>
                            <Col className='itemsColFooterRotd' span={8}>
                                <Picture className="fooImgLogoCtv" src={require("../resources/ctvonly.png").default} />
                                <Picture className="fooImgLogo" src={require("../resources/logoBlack2.png").default} />
                            </Col>
                            <Col className='itemsColFooterRotd' span={8}>
                                Instagram
                                <a href="https://instagram.com/ctvbz?igshid=YmMyMTA2M2Y=">
                                    <Picture className="fooIgImg fooImg" src={require("../resources/ig.png").default} />
                                </a>
                            </Col>
                            <Col className='itemsColFooterRotd' span={8}>
                                Spotify
                                <a href="https://open.spotify.com/user/0116ljq40q8901gk5ckcjv5hf?si=VsfLI1vEROqimMWoaKKZTA">
                                    <Picture className="fooSpotifyImgRotd fooImg" src={require("../resources/spotify-256.png").default} />
                                </a>
                            </Col>
                        </Row>
                    </div>
                </footer>
            )
        }
        else {
            return (
                <footer className="footer">
                    <div className="fooCont">
                        <Row>
                            <Col className='itemsColFooter' span={8}>
                                <Picture className="fooImgLogoCtv" src={require("../resources/ctvBlack.png").default} />
                                <Picture className="fooImgLogo" src={require("../resources/logoWhite2.png").default} />
                            </Col>
                            <Col className='itemsColFooter' span={8}>
                                Instagram
                                <a href="https://instagram.com/ctvbz?igshid=YmMyMTA2M2Y=">
                                    <Picture className="fooIgImg fooImg" src={require("../resources/ig.png").default} />
                                </a>
                            </Col>
                            <Col className='itemsColFooter' span={8}>
                                Spotify
                                <a href="https://open.spotify.com/user/0116ljq40q8901gk5ckcjv5hf?si=VsfLI1vEROqimMWoaKKZTA">
                                    <Picture className="fooSpotifyImg fooImg" src={require("../resources/spotify1.png").default} />
                                </a>
                            </Col>
                        </Row>
                    </div>
                </footer>
            )
        }
    }
}

export default Footer;