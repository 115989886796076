import React from "react";
import { FormForDate } from "../form/formInserimentoDate";
import { getAlbum, secondFunction } from "../Grid/gridAlbum";
import { GridAlbum } from "../Grid/gridAlbum";
import $ from 'jquery';
import { store } from "../redux/store";
import { Form, Input, Select, Button, DatePicker } from 'antd';
import { getIdAlbumPresentiFormDay } from "../Grid/gridAlbum";
import { setAlbumStoreAction, setMenuOpen } from "../../src/redux/actions";
import { useState } from "react";
import { getAlbums } from "../RecordOfTheDay/Rotd";
import { emptyalbumPresentiDirectoryCoverObj } from "../Grid/gridAlbum";



const FormDay = () => {
    // const [error, setError] = useState(null);
    // const [newDate, setNewDate] = useState(null);
    // function callGetAlbums() {
    //     getAlbums();
    // }

    var wi = parseInt($("#widthPaheAll").text());
    // // // //console.log("wi-recordOfTheDayInfoTopPage", wi);

    // $(document).ready(function () {
    //     if ($(window).width() > 900) {
    //     }
    // });

    const callRotdForm = (newDate) => {
        //console.log("callRotdForm");
        fetch("https://testctv.herokuapp.com/RecordOfTheDay", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "dataToday": newDate })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log("callRotdForm after Get");
                    // setItems(result);
                    // totalAlbum = result;
                    store.dispatch(setAlbumStoreAction(result));
                    //console.log(store.getState());
                    getIdAlbumPresentiFormDay(newDate);
                    setTimeout(() => {
                        // setIsLoaded(true);
                    }, 1000)
                },
                // Nota: è importante gestire gli errori qui
                // invece di un blocco catch() in modo da non fare passare
                // eccezioni da bug reali nei componenti.
                (error) => {
                    setTimeout(() => {
                        // setIsLoaded(true);
                        // //console.log("isloaded--Function: ", isLoadedFunction);
                    }, 1500)
                    // setError(error);
                }
            )
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const onFinish = values => {
        $(".myModal-infosite").css("display", "none");
        //countSubmitForm = 1;
        //console.log("valuesForm", values.startYear);
        if(values.startYear == undefined){
            return;
        }
        // var startDate = new Date(values.startYear._d);
        // var endDate = new Date(values.endyear._d);

        var modal = document.getElementById("myModal");
        modal.style.display = "block";

        emptyalbumPresentiDirectoryCoverObj();

        var date = formatDate(values.startYear._d);
        //console.log(date);
        // stateChanger(date);

        callRotdForm(date);

        // getAlbum();

    };


    if (wi < 900) {
        return (
            <div className="container-all-form-component">
                <div className="contaier-text-forom">Select Your Day</div>
                <Form name="complex-form" onFinish={values => onFinish(values)} labelCol={{ span: 8 }} layout={"inline"}>
                    <Form.Item label="" name="startYear">
                        <DatePicker format="MM-DD" inputClassName="datepicker-input-ctv" />
                    </Form.Item>
                    <Button className="buttonSubmitFormChangeDay" id="formButton" type="primary" htmlType="submit">
                        Change
                    </Button>
                </Form>
            </div>
        )
    }
    else {
        return (
            <div className="container-all-form-component">
                <div className="contaier-text-forom">Select Your Day</div>
                <Form name="complex-form" onFinish={values => onFinish(values)} labelCol={{ span: 8 }} layout={"inline"}>
                    <Form.Item label="" name="startYear">
                        <DatePicker format="MM-DD" inputClassName="datepicker-input-ctv" />
                    </Form.Item>
                    <Button className="buttonSubmitFormChangeDay" id="formButton" type="primary" htmlType="submit">
                        Change
                    </Button>
                </Form>
            </div>
        )
    }
}

export default FormDay;