import React from "react";
import $ from 'jquery';
import { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { setArticles } from "../redux/actions";
import { store } from "../redux/store";
import { slide as Menu } from 'react-burger-menu';
import "../home/home.css";
import "../home/splitPageHome.css";
import ScrollMagic from 'scrollmagic';
import { Link } from "react-router-dom";
import DiscoAnim from '../animation/discoAnimation';
import EqAnim from "../animation/eqAnimation";
import GridArticle from "./gridArticle";
import { Picture } from 'react-responsive-picture';
import { Button, Modal } from 'antd';
import "../home/homeResponsive.css";
import "../home/aboutUs.css";
// import useWindowDimensions from "./HookDimensions";
// import { WidthPage } from "./HookDimensions";
import WidthPage from "./HookDimensions";
import { Row, Col } from 'antd';
import Footer from "../footer/footer";



var styleAboutTitle = {
    width: "15%",
    minWidth: "200px",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 !important",
    borderRight: "solid 2px black",
    flexDirection: "column",
    flexWrap: "nowrap",
    fontSize: "17px",
    alignItems: "stretch",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "flex-start",
    top: "0px !important"
}
const styleVibez = {
    // marginTop: '25px',
    // marginLeft: '20px'
}

const runText = {
    animation: 'flowing 8s linear infinite',
    transform: 'translateX(100%)',
    marginBottom: '0px',
    fontSize: '16px'
}

var history = window.history;

// function useWindowSize() {
//   const [size, setSize] = useState([0, 0]);
//   useLayoutEffect(() => {
//     function updateSize() {
//       setSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateSize);
//     updateSize();
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);
//   return size;
// }


var wi = parseInt($("#widthPaheAll").text());

function triggerScroll() {
    if (wi > 900) {
        const controller = new ScrollMagic.Controller();
        // // // console.log("abouttitle", $(".about-title"));
        new ScrollMagic.Scene({
            // duration: '200%',
            triggerElement: '.about-title',
            triggerHook: 0
        })
            .setPin('.about-title')
            .addTo(controller);
    }
}

$(document).ready(function () {
    if (wi > 900) {
        // triggerScroll();
    }
    // if (window.location.href.split('/')[window.location.href.split('/').length - 1] == 'InfoRecordOfTheDay' || window.location.href.split('/')[window.location.href.split('/').length - 1] == 'InfoRecordOfTheDay#') {
    //   $(".selectedRecordOfTheDay").css("display", "block");
    //   $(".selectedHome").css("display", "none");
    //   $(".selectedContact").css("display", "none");
    //   $(".selectedMusicGraphics").css("display", "none");
    //   $(".divItemsRotdNav").addClass("divItemsNavbarSet");
    //   $(".divItemsHomeNav").removeClass("divItemsNavbarSet");
    // }
    // if (window.location.href.split('/')[window.location.href.split('/').length - 1] == 'Home' || window.location.href.split('/')[window.location.href.split('/').length - 1] == 'Home#') {
    //   $(".selectedRecordOfTheDay").css("display", "none");
    //   $(".selectedHome").css("display", "block");
    //   $(".selectedContact").css("display", "none");
    //   $(".selectedMusicGraphics").css("display", "none");
    //   $(".divItemsHomeNav").addClass("divItemsNavbarSet");
    //   $(".divItemsRotdNav").removeClass("divItemsNavbarSet");
    // }
});

//customOpening burgher Menu-----------------------------------------------------
const MyContext = React.createContext();

var urlSplit = window.location.href.split("/");
var currentPage = urlSplit[urlSplit.length - 1];

const AboutUs = () => {
    const menu = document.querySelector(".menu");
    const menuItems = document.querySelectorAll(".menuItem");
    const hamburger = document.querySelector(".hamburger");
    // const closeIcon = document.querySelector(".closeIcon");
    const menuIcon = document.querySelector(".menuIcon");
    const closeIcon = document.querySelector(".closeMenuRes");

    const today = new Date();
    const todayString = today.toISOString();

    function resetBorderBottomItemsMenu() {

    }

    function menuResponsive(action, item) {
        // // // console.log(action);
        if (action == "open") {
            menu.classList.add("showMenu");
            closeIcon.style.display = "block";
            menuIcon.style.display = "none";
            $('html, body').css({
                overflow: 'hidden',
                height: '100%'
            });
        }
        if (action == "close") {
            menu.classList.remove("showMenu");
            closeIcon.style.display = "none";
            menuIcon.style.display = "block";
            $('html, body').css({
                overflow: '',
                height: ''
            });
        }
        if (action == "item") {
            menu.classList.remove("showMenu");
            closeIcon.style.display = "none";
            menuIcon.style.display = "block";
            $('html, body').css({
                overflow: '',
                height: ''
            });
            if (item == "AllArticles") {
                $('html, body').animate({ scrollTop: 0 }, 'fast');
                setArticles(allArticles);
                $(".infoTopPageArticles").html("All Articles:");

                //inserico bordi a
                $("#itemResAllArticles").css("border-bottom", "0.8px solid black");
                //rimuovo bordi items da
                $("#itemResAlbumOfTheMonth").css("border-bottom", "");
                $("#itemResAnniversary").css("border-bottom", "");
                $("#itemResSoundtrackCorner").css("border-bottom", "");
                $("#itemResVibeChecking").css("border-bottom", "");
                $("#itemResPlaylist").css("border-bottom", "");
                $("#itemResReviews").css("border-bottom", "");
                $("#itemResContactUs").css("border-bottom", "");
                $("#itemResMusicGraphics").css("border-bottom", "");
            }
            else if (item == "AlbumOfTheMonth") {
                $('html, body').animate({ scrollTop: 0 }, 'fast');
                var newArray = allArticles.filter(function (el) {
                    return el.genre == "AlbumOfTheMonth"
                });
                setArticles(newArray);
                $(".infoTopPageArticles").html("Album Of The Month:");

                $("#itemResAllArticles").css("border-bottom", "");
                $("#itemResAlbumOfTheMonth").css("border-bottom", "0.8px solid black");
                $("#itemResAnniversary").css("border-bottom", "");
                $("#itemResSoundtrackCorner").css("border-bottom", "");
                $("#itemResVibeChecking").css("border-bottom", "");
                $("#itemResPlaylist").css("border-bottom", "");
                $("#itemResReviews").css("border-bottom", "");
                $("#itemResContactUs").css("border-bottom", "");
                $("#itemResMusicGraphics").css("border-bottom", "");
            }
            else if (item == "Anniversary") {
                $('html, body').animate({ scrollTop: 0 }, 'fast');
                var newArray = allArticles.filter(function (el) {
                    return el.genre == "Anniversary"
                });
                setArticles(newArray);
                $(".infoTopPageArticles").html("Anniversary:");

                $("#itemResAllArticles").css("border-bottom", "");
                $("#itemResAlbumOfTheMonth").css("border-bottom", "");
                $("#itemResAnniversary").css("border-bottom", "0.8px solid black");
                $("#itemResSoundtrackCorner").css("border-bottom", "");
                $("#itemResVibeChecking").css("border-bottom", "");
                $("#itemResPlaylist").css("border-bottom", "");
                $("#itemResReviews").css("border-bottom", "");
                $("#itemResContactUs").css("border-bottom", "");
                $("#itemResMusicGraphics").css("border-bottom", "");
            }
            else if (item == "SoundtrackCorner") {
                $('html, body').animate({ scrollTop: 0 }, 'fast');
                // console.log("ssssssssllllll", allArticles);
                var newArray = allArticles.filter(function (el) {
                    // console.log("article genre", el.genre);
                    return el.genre == "SoundtrackCorner"
                });
                setArticles(newArray);
                $(".infoTopPageArticles").html("Soundtrack Corner:");

                $("#itemResAllArticles").css("border-bottom", "");
                $("#itemResAlbumOfTheMonth").css("border-bottom", "");
                $("#itemResAnniversary").css("border-bottom", "");
                $("#itemResSoundtrackCorner").css("border-bottom", "0.8px solid black");
                $("#itemResVibeChecking").css("border-bottom", "");
                $("#itemResPlaylist").css("border-bottom", "");
                $("#itemResReviews").css("border-bottom", "");
                $("#itemResContactUs").css("border-bottom", "");
                $("#itemResMusicGraphics").css("border-bottom", "");
            }
            else if (item == "VibeChecking") {
                $('html, body').animate({ scrollTop: 0 }, 'fast');
                var newArray = allArticles.filter(function (el) {
                    return el.genre == "VibeChecking"
                });
                setArticles(newArray);
                $(".infoTopPageArticles").html("Vibe Checking:");

                $("#itemResAllArticles").css("border-bottom", "");
                $("#itemResAlbumOfTheMonth").css("border-bottom", "");
                $("#itemResAnniversary").css("border-bottom", "");
                $("#itemResSoundtrackCorner").css("border-bottom", "");
                $("#itemResVibeChecking").css("border-bottom", "0.8px solid black");
                $("#itemResPlaylist").css("border-bottom", "");
                $("#itemResReviews").css("border-bottom", "");
                $("#itemResContactUs").css("border-bottom", "");
                $("#itemResMusicGraphics").css("border-bottom", "");
            }
            else if (item == "Playlist") {
                $('html, body').animate({ scrollTop: 0 }, 'fast');
                var newArray = allArticles.filter(function (el) {
                    return el.genre == "Playlist"
                });
                setArticles(newArray);
                $(".infoTopPageArticles").html("Playlist:");

                $("#itemResAllArticles").css("border-bottom", "");
                $("#itemResAlbumOfTheMonth").css("border-bottom", "");
                $("#itemResAnniversary").css("border-bottom", "");
                $("#itemResSoundtrackCorner").css("border-bottom", "");
                $("#itemResVibeChecking").css("border-bottom", "");
                $("#itemResPlaylist").css("border-bottom", "0.8px solid black");
                $("#itemResReviews").css("border-bottom", "");
                $("#itemResContactUs").css("border-bottom", "");
                $("#itemResMusicGraphics").css("border-bottom", "");
            }
            else if (item == "Reviews") {
                $('html, body').animate({ scrollTop: 0 }, 'fast');
                var newArray = allArticles.filter(function (el) {
                    return el.genre == "Reviews"
                });
                setArticles(newArray);
                $(".infoTopPageArticles").html("Reviews:");

                $("#itemResAllArticles").css("border-bottom", "");
                $("#itemResAlbumOfTheMonth").css("border-bottom", "");
                $("#itemResAnniversary").css("border-bottom", "");
                $("#itemResSoundtrackCorner").css("border-bottom", "");
                $("#itemResVibeChecking").css("border-bottom", "");
                $("#itemResPlaylist").css("border-bottom", "");
                $("#itemResReviews").css("border-bottom", "0.8px solid black");
                $("#itemResContactUs").css("border-bottom", "");
                $("#itemResMusicGraphics").css("border-bottom", "");
            }
            else if (item == "ContactUs") {
                $('html, body').animate({ scrollTop: 0 }, 'fast');
                var newArray = allArticles.filter(function (el) {
                    return el.genre == "ContactUs"
                });
                setArticles(newArray);
                $(".infoTopPageArticles").html("Contact Us:");

                $("#itemResAllArticles").css("border-bottom", "");
                $("#itemResAlbumOfTheMonth").css("border-bottom", "");
                $("#itemResAnniversary").css("border-bottom", "");
                $("#itemResSoundtrackCorner").css("border-bottom", "");
                $("#itemResVibeChecking").css("border-bottom", "");
                $("#itemResPlaylist").css("border-bottom", "");
                $("#itemResReviews").css("border-bottom", "");
                $("#itemResContactUs").css("border-bottom", "0.8px solid black");
                $("#itemResMusicGraphics").css("border-bottom", "");
            }
        }
        else {
            // // // console.log("Items non presente");
        }
    }

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [articles, setArticles] = useState([]);
    const [allArticles, setAllArticles] = useState([]);
    const [visible, setVisible] = useState(false);
    const [openBurgher, setOpenBurgher] = useState(false);

    const [selectetTitleArticle, setSelectetTitleArticle] = useState();
    const [selectetTextArticle, setSelectetTextArticle] = useState();
    const [selectetImgArticle, setSelectetImgArticle] = useState("logo1.png");

    var elementsArticles = [];

    $("#react-burger-cross-btn").click(function () {
        // $('html, body').css({
        //   overflow: '',
        //   height: ''
        // });
    });

    // const [width, height] = useWindowSize();
    // // // console.log("width gridArticles", width);

    var wi = parseInt($("#widthPaheAll").text());
    // // // console.log("wi", wi);

    if (wi < 1430) {
        // // // console.log("1430");
        styleAboutTitle = {
            width: "15%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 !important",
            borderRight: "solid 2px black",
            flexDirection: "column",
            flexWrap: "nowrap",
            fontSize: "16px",
            alignItems: "stretch",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            top: "0px !important"
        };
    }
    if (wi < 1320) {
        // // // console.log("1320");
        styleAboutTitle = {
            width: "15%",
            minWidth: "150px",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 !important",
            borderRight: "solid 2px black",
            flexDirection: "column",
            flexWrap: "nowrap",
            fontSize: "16px",
            alignItems: "stretch",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            top: "0px !important"
        };
    }
    if (wi < 1090) {
        // // // console.log("1090");
        styleAboutTitle = {
            width: "15%",
            minWidth: "130px",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 !important",
            borderRight: "solid 2px black",
            flexDirection: "column",
            flexWrap: "nowrap",
            fontSize: "14px",
            alignItems: "stretch",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            top: "0px !important"
        };
    }
    else {
        // styleAboutTitle.fontSize = "17px";
    }
    if (wi < 900) {
        // // // console.log("900");
        styleAboutTitle = {
            width: "0%",
            minWidth: "0px",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 !important",
            borderRight: "solid 2px black",
            flexDirection: "column",
            flexWrap: "nowrap",
            fontSize: "14px",
            alignItems: "stretch",
            display: "none",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            top: "0px !important"
        };
        // $(".about").css({"display": "flex",
        //  "align-items": "center"
        // });
        // $(".scrollmagic-pin-spacer").attr('style', 'width: 0% !important');
        // $(".scrollmagic-pin-spacer").remove();
    }
    else {
        $(".scrollmagic-pin-spacer").attr('style', 'width: 15% !important');
    }

    useEffect(() => {
        $("#itemResAllArticles").css("border-bottom", "0.8px solid black");
        $("#allArticles").css("border-bottom", "0.8px solid black");


        // if (wi > 900) {
        //     const controller = new ScrollMagic.Controller();

        //     // // // console.log("abouttitle", $(".about-title").length);
        //     new ScrollMagic.Scene({
        //         // duration: '200%',
        //         triggerElement: '.about-title',
        //         triggerHook: 0
        //     })
        //         .setPin('.about-title')
        //         .addTo(controller);
        // }


        fetch("https://testctv.herokuapp.com/articles")
            .then(res => res.json())
            .then(
                (result) => {
                    // // // console.log("Articoli----", result);
                    setArticles(result);
                    setAllArticles(result);
                },
                // Nota: è importante gestire gli errori qui
                // invece di un blocco catch() in modo da non fare passare
                // eccezioni da bug reali nei componenti.
                (error) => {
                    setError(error);
                }
            )
    }, [])

    function changeColorArticle(i) {
        // // // console.log("e+", i);
        if (wi > 900) {
            $("#" + i).css("background-color", "black");
            $("#" + i).css("color", "white");
        }
    }

    function resetColorArticle(i) {
        // // // console.log(i);
        if (wi > 900) {
            $("#" + i).css("background-color", "white");
            $("#" + i).css("color", "black");
        }
    }

    const lastItems = {
        marginBottom: '70px'
    }

    function navigatorArticle(link) {
        // // // console.log("link", link);
        if (link == "All") {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            setArticles(allArticles);
            $(".infoTopPageArticles").html("All Articles:");

            $("#allArticles").css("border-bottom", "0.8px solid black");
            $("#albumOfTheMonth").css("border-bottom", "");
            $("#anniversary").css("border-bottom", "");
            $("#soundtrackCorner").css("border-bottom", "");
            $("#vibeChecking").css("border-bottom", "");
            $("#playlist").css("border-bottom", "");
            $("#reviews").css("border-bottom", "");
            $("#contactUs").css("border-bottom", "");
        }
        if (link == "month") {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            var newArray = allArticles.filter(function (el) {
                return el.genre == "AlbumOfTheMonth"
            });
            setArticles(newArray);
            $(".infoTopPageArticles").html("Album of The Month:");

            $("#allArticles").css("border-bottom", "");
            $("#albumOfTheMonth").css("border-bottom", "0.8px solid black");
            $("#anniversary").css("border-bottom", "");
            $("#soundtrackCorner").css("border-bottom", "");
            $("#vibeChecking").css("border-bottom", "");
            $("#playlist").css("border-bottom", "");
            $("#reviews").css("border-bottom", "");
            $("#contactUs").css("border-bottom", "");
        }
        if (link == "anniversary") {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            var newArray = allArticles.filter(function (el) {
                return el.genre == "Anniversary"
            });
            setArticles(newArray);
            $(".infoTopPageArticles").html("Anniversary:");

            $("#allArticles").css("border-bottom", "");
            $("#albumOfTheMonth").css("border-bottom", "");
            $("#anniversary").css("border-bottom", "0.8px solid black");
            $("#soundtrackCorner").css("border-bottom", "");
            $("#vibeChecking").css("border-bottom", "");
            $("#playlist").css("border-bottom", "");
            $("#reviews").css("border-bottom", "");
            $("#contactUs").css("border-bottom", "");
        }
        if (link == "SoundtrackCorner") {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            var newArray = allArticles.filter(function (el) {
                return el.genre == "SoundtrackCorner"
            });
            setArticles(newArray);
            $(".infoTopPageArticles").html("Soundtrack Corner:");

            $("#allArticles").css("border-bottom", "");
            $("#albumOfTheMonth").css("border-bottom", "");
            $("#anniversary").css("border-bottom", "");
            $("#soundtrackCorner").css("border-bottom", "0.8px solid black");
            $("#vibeChecking").css("border-bottom", "");
            $("#playlist").css("border-bottom", "");
            $("#reviews").css("border-bottom", "");
            $("#contactUs").css("border-bottom", "");
        }
        if (link == "vibeChecking") {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            var newArray = allArticles.filter(function (el) {
                return el.genre == "VibeChecking"
            });
            setArticles(newArray);
            $(".infoTopPageArticles").html("Vibe Checking:");

            $("#allArticles").css("border-bottom", "");
            $("#albumOfTheMonth").css("border-bottom", "");
            $("#anniversary").css("border-bottom", "");
            $("#soundtrackCorner").css("border-bottom", "");
            $("#vibeChecking").css("border-bottom", "0.8px solid black");
            $("#playlist").css("border-bottom", "");
            $("#reviews").css("border-bottom", "");
            $("#contactUs").css("border-bottom", "");
        }
        if (link == "playlist") {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            var newArray = allArticles.filter(function (el) {
                return el.genre == "Playlist"
            });
            setArticles(newArray);
            $(".infoTopPageArticles").html("Playlist:");

            $("#allArticles").css("border-bottom", "");
            $("#albumOfTheMonth").css("border-bottom", "");
            $("#anniversary").css("border-bottom", "");
            $("#soundtrackCorner").css("border-bottom", "");
            $("#vibeChecking").css("border-bottom", "");
            $("#playlist").css("border-bottom", "0.8px solid black");
            $("#reviews").css("border-bottom", "");
            $("#contactUs").css("border-bottom", "");
        }
        if (link == "reviews") {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            var newArray = allArticles.filter(function (el) {
                return el.genre == "Reviews"
            });
            setArticles(newArray);
            $(".infoTopPageArticles").html("Reviews:");

            $("#allArticles").css("border-bottom", "");
            $("#albumOfTheMonth").css("border-bottom", "");
            $("#anniversary").css("border-bottom", "");
            $("#soundtrackCorner").css("border-bottom", "");
            $("#vibeChecking").css("border-bottom", "");
            $("#playlist").css("border-bottom", "");
            $("#reviews").css("border-bottom", "0.8px solid black");
            $("#contactUs").css("border-bottom", "");
        }
        if (link == "contactUs") {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            // var newArray = allArticles.filter(function (el) {
            //   return el.genre == "Reviews"
            // });
            // setArticles(newArray);
            $(".infoTopPageArticles").html("Contact Us:");

            $("#allArticles").css("border-bottom", "");
            $("#albumOfTheMonth").css("border-bottom", "");
            $("#anniversary").css("border-bottom", "");
            $("#soundtrackCorner").css("border-bottom", "");
            $("#vibeChecking").css("border-bottom", "");
            $("#playlist").css("border-bottom", "");
            $("#reviews").css("border-bottom", "");
            $("#contactUs").css("border-bottom", "0.8px solid black");
        }
    }

    function setModalArticle(article) {
        setVisible(true);
        setSelectetTitleArticle(article.title);
        setSelectetTextArticle(article.textarticle);
        setSelectetImgArticle(article.img);
        // // // console.log(article);
    }

    const ModalArticle = () => {
        return (
            <>
                <Modal
                    title={selectetTitleArticle}
                    centered
                    cancelButtonProps={{ 'id': 'cancelButtonModal' }}
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    width={1000}
                >
                    <Picture className="imgArticle imgArticleModal" src={require("../resources/imgArticles/" + selectetImgArticle).default} />
                    <div className="containerTextArticleModal" dangerouslySetInnerHTML={{ __html: selectetTextArticle }}></div>
                </Modal>
            </>
        );
    };



    function setOpenMenuBurgher() {
        // if (openBurgher == false) {
        //   setOpenBurgher(true);
        //   // $(".about").css("background-color", "rgba(0, 0, 0, 0.3)");
        //   // $(".imgArticle").attr('style', 'z-index: -1 !important');
        //   // $(".txtArticle").css("overflow", "hinerit");
        //   // // // console.log(openBurgher);
        // }
        // else {
        //   setOpenBurgher(false);
        //   // // // console.log(openBurgher);
        // }
        setOpenBurgher(true);
        // // // console.log(openBurgher);
    }

    function openMenuBurgher() {
        return openBurgher;
    }

    const mStyleResponsive = {
        // height: "115px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        maxHeight: "110px",
        paddingTop: "6px",
        paddingBottom: "6px",
        justifyContent: "center",
        zIndex: "50"
    };
    const mStyleResponsive1 = {
        // height: "115px",
        display: "flex",
        alignItems: "flexStart",
        justifyContent: "center",
        flexDirection: "column",
        maxHeight: "110px",
        paddingTop: "6px",
        paddingBottom: "6px"
    };

    function backFromAboutUs() {
        history.back();
        $(".menuBarApp").css("display", "flex");
    }

    window.addEventListener('popstate', function (event) {
        // The event object contains information about the state change
        console.log('Page changed!', event.state);
        const controller = new ScrollMagic.Controller();
        if ($(window).width() > 900) {
            if ($(".about-title-r")) {
                // // console.log("kuhkj");
                new ScrollMagic.Scene({
                    // duration: '200%',
                    triggerElement: '.about-title-r',
                    triggerHook: 0
                })
                    .setPin('.about-title-r')
                    .addTo(controller);
            }
        }
    });

    function setMenuFromEq() {
        $(".divItemsRotdNav").addClass("divItemsNavbarSet");
        $(".divItemsHomeNav").removeClass("divItemsNavbarSet");
    }

    // console.log("storestate", store.getState().length);
    // const textSlideInfoRotd = "Today's " + todayString.substring(5, 10) + " have been released " + store.getState().length + " Albums. check them out on RecordOfTheDay";
    const textSlideInfoRotd = store.getState().length + " Albums released Today.  check them out";

    const RenderArticles = ({ articles }) => (
        articles.map((article, i) => (
            <div>
                {i % 2 === 1 ? (
                    <div className={article.id} key={i} id="containerSingleArticle" onClick={() => setModalArticle(article)} onMouseOver={() => changeColorArticle(article.id)} onMouseLeave={() => resetColorArticle(article.id)}>
                        <div className="containerTextArticle">
                            <div id={article.id} className="titleSingleArticle">
                                <div className="titleAr">
                                    {article.title.length > 30 ? (
                                        <div className="setArticleFontTitle">{article.title}</div>
                                    ) : (
                                        article.title
                                    )}
                                </div>
                            </div>
                            <div className="img-TextArticle">
                                <div className="textSingleArticle textSingleArticleEven">
                                    <div className="txtArticle" dangerouslySetInnerHTML={{ __html: article.textarticle }}></div>
                                    <div className="infoArticleAuthor infoArticleAuthorEven">
                                        {/* <div className="nameAuthor">
                      Mario Rossi
                    </div> */}
                                        {/* <div className="dateArticle">
                    {article.data}
                    </div> */}
                                    </div>
                                </div>
                                <Picture className="imgArticle" src={require("../resources/imgArticles/" + article.img).default} />
                                {/* <img className="imgArticle" src={require("../resources/imgArticles/" + article.img).default}></img> */}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={article.id} key={i} id="containerSingleArticle" onClick={() => setModalArticle(article)} onMouseOver={() => changeColorArticle(article.id)} onMouseLeave={() => resetColorArticle(article.id)}>
                        <div className="containerTextArticle">
                            <div id={article.id} className="titleSingleArticle oddTitleArticle">
                                <div className="titleAr">
                                    {article.title.length > 32 ? (
                                        <div className="setArticleFontTitle">{article.title}</div>
                                    ) : (
                                        article.title
                                    )}
                                </div>
                            </div>
                            <div className="img-TextArticle oddArticle">
                                <Picture className="imgArticle" src={require("../resources/imgArticles/" + article.img).default} />
                                {/* <img className="imgArticle" src={require("../resources/imgArticles/" + article.img).default}></img> */}
                                <div className="textSingleArticle textSingleArticleOdd">
                                    <div className="txtArticle" dangerouslySetInnerHTML={{ __html: article.textarticle }}></div>
                                    <div className="infoArticleAuthor infoArticleAuthorOdd">
                                        {/* <div className="nameAuthor">
                      Mario Rossi
                    </div> */}
                                        {/* <div className="dateArticle">
                    {article.data}
                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ))
    );

    if ($(window).width() > 900) {
        return (
            <div className="about-pages aboutPageAboutUs">
                <div className="containerAboutUs">
                    <Picture onClick={() => backFromAboutUs()} className="backFromAboutTitle" src={require("../resources/downward-arrow.png").default} />
                    <div className="txtAllArticle">
                        Check the Vibez began in 2022 as a creative outlet for its founders to speak about new releases. <br></br>
                        Check the Vibez is an independent music blog with established platforms in digital media that promotes and supports artists, releases, and events, featuring the most intriguing emerging bands as well as renowned acts and music legends.<br></br>
                        Check the Vibez is an open online space where writers and creators post their views and thoughts about music; our goal is to share good vibes through original ideas for music lovers. <br></br> The contents range from reviews, anniversaries, playlists, and various editorials, such as cinema. <br></br>
                        Come and Check the Vibez.
                    </div>
                    <div className="txtTitleAboutUs">About Us</div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="aboutPageAboutUsRes">
                <div className="containerAboutUsRes">
                    <Picture onClick={() => backFromAboutUs()} className="backFromAboutTitleRes" src={require("../resources/downward-arrow.png").default} />
                    <div className="txtTitleAboutUsRes">About Us</div>
                    <div className="txtAllArticleRes">
                        Check the Vibez began in 2022 as a creative outlet for its founders to speak about new releases. <br></br>
                        Check the Vibez is an independent music blog with established platforms in digital media that promotes and supports artists, releases, and events, featuring the most intriguing emerging bands as well as renowned acts and music legends.<br></br>
                        Check the Vibez is an open online space where writers and creators post their views and thoughts about music; our goal is to share good vibes through original ideas for music lovers. <br></br> The contents range from reviews, anniversaries, playlists, and various editorials, such as cinema. <br></br>
                        Come and Check the Vibez.
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUs;