export const reducer = (state, action) => {
    switch (action.type) {
        case "INCREMENT":
            return state.number + action.payload
        case "ASSIGN":
            return state.album = action.payload
        case "JSONSTATE":
            return state.album = action.payload
        case "MENU":
            return state.keys = action.payload
        case "ARTICLES":
            return state.articles = action.payload
        default:
            return state
    }
}