import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import "./dataGrid.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function FormRow(props) {
  const classes = useStyles();
  const { state } = props;
  // // // // // console.log("-----------------xxxxxxx---------------",state);

  var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
  //// // // // console.log(width);

  //logica per popolare dinamicamente la griglia
  var rows = [];
  if (state.arrAlbum) {
    // console.log("state-Arralbum", state);
    if (state.divElements.length == 0) {
      rows.push(
        <Grid xs={12}>
          <div className='noCoverFoundText'>
            <Paper className={classes.paper}>No Cover Found</Paper>
          </div>
        </Grid>
      );
    }
    else {
      for (var i = 0; i < state.arrAlbum.length; i++) {
        if (width >= 900) {
          // console.log("99999", state.divElements.length);
          if (state.divElements.length < 4) {
            if (state.divElements.length == 1) {
              rows.push(
                <Grid key={i} item xs={12}>
                  <Paper id="singleAlbumGrid" className={classes.paper}>{state.divElements[i]}</Paper>
                </Grid>
              );
            }
            if (state.divElements.length == 2) {
              rows.push(
                <Grid key={i} item xs={6}>
                  <Paper id="singleAlbumGrid" className={classes.paper}>{state.divElements[i]}</Paper>
                </Grid>
              );
            }
            if (state.divElements.length == 3) {
              rows.push(
                <Grid key={i} item xs={4}>
                  <Paper id="singleAlbumGrid" className={classes.paper}>{state.divElements[i]}</Paper>
                </Grid>
              );
            }
          }
          else {
            rows.push(
              <Grid key={i} item xs={3}>
                <Paper id="singleAlbumGrid" className={classes.paper}>{state.divElements[i]}</Paper>
              </Grid>
            );
          }
        }
        else if (width < 900 && width >= 551) {
          if (state.divElements.length < 3) {
            if (state.divElements.length == 1) {
              rows.push(
                <Grid key={i} item xs={12}>
                  <Paper className={classes.paper}>{state.divElements[i]}</Paper>
                </Grid>
              );
            }
            if (state.divElements.length == 2) {
              rows.push(
                <Grid key={i} item xs={6}>
                  <Paper className={classes.paper}>{state.divElements[i]}</Paper>
                </Grid>
              );
            }
            if (state.divElements.length == 3) {
              rows.push(
                <Grid key={i} item xs={4}>
                  <Paper className={classes.paper}>{state.divElements[i]}</Paper>
                </Grid>
              );
            }
          }
          else {
            rows.push(
              <Grid key={i} item xs={4}>
                <Paper className={classes.paper}>{state.divElements[i]}</Paper>
              </Grid>
            );
          }
        }
        else if (width < 550) {
          if (state.divElements.length < 2) {
            if (state.divElements.length == 1) {
              rows.push(
                <Grid key={i} item xs={12}>
                  <Paper className={classes.paper}>{state.divElements[i]}</Paper>
                </Grid>
              );
            }
          }
          else {
            rows.push(
              <Grid key={i} item xs={6}>
                <Paper className={classes.paper}>{state.divElements[i]}</Paper>
              </Grid>
            );
          }
        }
      }
    }
  }
  else {
    rows.push(
      <Grid key={0} item xs={24}>
        <Paper className={classes.paper}><div></div></Paper>
      </Grid>
    );
  }

  return (
    //xs definisce il numero di colonne in una riga, con un massimo di 12 in questo caso 3 suddivide lo spazio della riga in 4
    <React.Fragment>
      {rows}
    </React.Fragment>
  );
}


export function NestedGrid(props) {
  const classes = useStyles();
  const { state } = props;
  // // // console.log("state Nasted Grid", state);

  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Grid container item spacing={8}>
          <FormRow classes={classes} state={state} />
        </Grid>
      </Grid>
    </div>
  );
}