import React from 'react';
import { useMenu } from './MenuContext';
import { Picture } from 'react-responsive-picture';

export default function MenuToggleButton() {
    const { toggleMenu } = useMenu();

    return (
        <div className='div-headroombar-right'  onClick={() => toggleMenu()}>
            <Picture className="img-hamburgher-menu" src={require("../resources/hamburgher-menu.png").default} />
            <Picture className="img-hamburgher-arrow" src={require("../resources/icons8-arrow-96.png").default} />
        </div>
    );
}
